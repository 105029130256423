// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}
// iframe {
//     display: none !important;
// }
// ==============================|| PERFECT SCROLLBAR ||============================== //

#file-input2 {
    display: none !important;
}

.scrollbar-container {
    ul {
        padding: 0px !important;
    }

    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.list-orders {
    .selected {
        margin: 10px 0px;

        p {
            font-family: Mulish-Light !important;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            color: #999999;
            margin-right: 15px;
        }

        .col-md-12 {
            column-gap: 20px;
        }

        .pinkbtn {
            padding: 6px 30px;
            background: #e35757;
            text-transform: initial;
            box-shadow: none;
            border: none;
            margin-right: 5px;
            color: white;
        }

        .greenbtn {
            padding: 6px 30px;
            background: #57e376;
            text-transform: initial;
            box-shadow: none;
            color: white;
            border: none;
            margin-right: 5px;
        }
    }

    .order-tracking {
        .heading {
            margin-bottom: 10px;

            h2 {
                margin-bottom: 0px;
                font-size: 21px;
                line-height: 1.7;
            }

            .btn-primary {
                padding: 6px 35px;
                background: #c3724e;
                text-transform: initial;
                box-shadow: none;
                border: none;
                font-family: 'FuturaPtBook' !important;
            }
        }

        .align-input {
            text-align: end;

            .tracking-nmb {
                .input-field {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }

                button {
                    padding: 6px 30px;
                    background: #c3724e;
                    text-transform: initial;
                    box-shadow: none;
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                .MuiFormControl-root {
                    width: 100%;

                    .css-q8ei9h-MuiInputBase-root {
                        .MuiInputBase-input {
                            width: 100%;
                            font-size: 14px;
                            color: gray;
                            padding: 7px 12px;
                        }
                    }
                }
            }
        }

        .tabs-btn {
            margin: 13px 0px;
            text-align: left;

            .btn-secondary {
                color: #fff;
                background-color: #999999 !important;
                border-color: #999999 !important;
                font-family: Mulish-Light !important;
            }

            .btn-secondary:focus {
                box-shadow: none !important;
            }

            .btn-group {
                flex-wrap: wrap !important;
            }

            .btn-check {
                &:active + .btn-secondary {
                    color: #fff;
                    background-color: #c3724e !important;
                    border-color: #c3724e !important;
                    outline: none;
                }

                &:checked + .btn-secondary {
                    color: #fff;
                    background-color: #c3724e !important;
                    border-color: #c3724e !important;
                    outline: none;
                }
            }

            .btn-secondary.active {
                color: #fff;
                background-color: #c3724e !important;
                border-color: #c3724e !important;
                outline: none;
            }

            .btn-secondary {
                &:active {
                    color: #fff;
                    background-color: #c3724e !important;
                    border-color: #c3724e !important;
                    outline: none;
                }
            }

            .show {
                > .btn-secondary.dropdown-toggle {
                    color: #fff;
                    background-color: #c3724e !important;
                    border-color: #c3724e !important;
                    outline: none;
                }
            }
        }
    }

    .search-id {
        margin-bottom: 5px;

        .btn-primary {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            border: none;
            font-family: Mulish-SemiBold !important;

            @media screen and (max-width: 767px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .search-fields {
            margin-bottom: 5px;

            .MuiOutlinedInput-root {
                width: 100% !important;

                .MuiSelect-select {
                    width: 100% !important;
                    padding: 7px 12px;
                    border: 1px solid #ced4da;
                    color: #808080;
                    border-radius: 4px;
                    background-color: #fcfcfb;
                    margin-bottom: 10px;

                    @media screen and (max-width: 768px) {
                        margin-top: 10px;
                    }

                    em {
                        font-style: normal;
                        font-family: 'FuturaPtBook' !important;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border: none;
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiFormControl-root {
                width: 100%;

                .MuiInputBase-root {
                    .MuiInputBase-input {
                        width: 100% !important;
                        font-size: 14px;
                        color: gray;
                        padding: 7px 12px;
                    }

                    .MuiInputBase-input::placeholder {
                        color: #808080 !important;
                        font-weight: 400;
                        opacity: 1;
                    }
                }
            }

            .input-field {
                margin-bottom: 10px;
            }
        }

        .dropdown-width {
            width: 200px;

            .MuiSelect-select {
                margin-bottom: 0px !important;
            }
        }

        .dropdown-width1 {
            width: 300px;

            .MuiSelect-select {
                margin-bottom: 0px !important;
            }
        }

        .search-btn {
            button {
                padding: 6px 30px;
                background: #c3724e;
                text-transform: initial;
                box-shadow: none;
                font-size: 14px;
                margin-bottom: 10px;
                font-family: 'FuturaPtBook' !important;
            }
        }

        .MuiInputBase-input {
            width: 100% !important;
        }
    }

    .complete-delivery {
        .tracking-nmb {
            p {
                margin-bottom: 7px;
                color: gray;
            }

            .MuiFormControl-root {
                width: 100%;

                .css-q8ei9h-MuiInputBase-root {
                    .MuiInputBase-input {
                        width: 100%;
                        font-size: 14px;
                        color: gray;
                        padding: 7px 12px;
                    }
                }
            }

            .search-fields {
                .input-field {
                    margin-bottom: 10px;
                }
            }
        }

        .complete {
            background-color: #0c9c00;
            opacity: 0.5;
            margin-bottom: 10px;
            padding: 6px 29px;
            margin-right: 10px;
            box-shadow: none;
        }

        .delivery {
            background-color: #00809c;
            opacity: 0.5;
            margin-bottom: 10px;
            padding: 6px 29px;
            box-shadow: none;
        }
    }

    .detail-btn {
        background: #4eaec3;
        border-radius: 6px;
        border: none;
        color: white;
        font-weight: 400;
        padding: 5px 20px;
        font-family: 'FuturaPtBook' !important;
    }

    .delete-btn {
        background: #e35757;
        border-radius: 6px;
        border: none;
        color: white;
        font-weight: 400;
        padding: 5px 20px;
        font-family: 'FuturaPtBook' !important;
    }

    .css-1bzewlt-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: #c3724e;
    }

    .css-1bzewlt-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
        color: #c3724e;
    }

    .sc-dlVxhl {
        .sc-bdvvtL {
            padding: 10px;
            border-radius: 10px;
        }

        .rdt_Table {
            .rdt_TableHeadRow {
                justify-content: space-between !important;
            }

            .rdt_TableHead {
                .sc-hKwDye {
                    justify-content: flex-start !important;
                    font-size: 14px;
                }
            }

            .rdt_TableBody {
                .rdt_TableRow {
                    padding: 10px 0px !important;
                    justify-content: space-between !important;
                }

                .ddMYzF,
                .fFBlx {
                    justify-content: flex-start !important;
                }

                .sc-hKwDye {
                    justify-content: flex-start !important;

                    .product-img {
                        margin-right: 10px !important;
                        min-width: 55px;
                    }

                    .product-detail {
                        P {
                            margin-bottom: 2px !important;
                            white-space: break-spaces;
                        }
                    }
                }
            }
        }
    }
}

.add-thread {
    h2 {
        margin-bottom: 20px;
        font-size: 21px;
        line-height: 1.7;
    }

    .input-field {
        margin-bottom: 15px;

        label {
            margin-bottom: 4px;
            font-size: 15px;
            font-weight: 600;
        }

        span {
            margin-bottom: 4px;
            font-size: 15px;
            font-weight: 600;
        }
    }

    .search-fields {
        .MuiFormControl-root {
            width: 100%;

            .MuiOutlinedInput-root {
                width: 100% !important;
                padding: 11px 0.75rem;
                border: 1px solid #999999;
                color: #808080;
                border-radius: 4px;
                background-color: #f8f8f8;

                .MuiOutlinedInput-input {
                    border: none;
                    padding: 0px;
                }
            }
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                padding: 11px 0.75rem;
                border: 1px solid #999999;
                color: #808080;
                border-radius: 4px;
                background-color: #f8f8f8;
                width: 100% !important;

                em {
                    font-style: normal;
                    font-weight: 100;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }

    .brownBtn {
        background-color: #c3724e;
        border-color: #c3724e;
        font-family: 'FuturaPtBook' !important;
    }
    .generateBtn {
        background-color: #c3724e;
        border-color: #c3724e;
        font-family: 'FuturaPtBook' !important;
        line-height: 30px;
        font-size: 12px;
        width: 110px !important;
        padding: 5px;
    }
    .approved {
        h3 {
            margin: 0px;
            font-size: 20px;
        }

        .toggle {
            .Mui-selected {
                color: #ffffff;
                background-color: #c3724e !important;
            }

            .MuiToggleButton-root {
                padding: 6px 25px;
                background: #999999;
                color: white;
            }
        }
    }
}

.view-product-detail {
    h2 {
        margin-bottom: 30px;
        font-size: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
    }

    .watch-product {
        margin-bottom: 30px;

        img {
            border-radius: 7px !important;
        }

        .rounded {
            border-radius: 11px !important;
        }
    }

    .product-specs {
        background: white;
        padding: 20px;
        border-radius: 10px;

        h5 {
            font-size: 22px;
            padding-bottom: 10px;
        }

        .sub-heading {
            color: Grey;
            font-weight: 500;
            margin-bottom: 10px;
            font-size: 14px;
        }

        p {
            font-size: 18px;
            color: black;
        }

        .grid {
            display: inline-grid;

            p {
                color: Grey;
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 5px;
            }

            span {
                padding: 0px;
            }

            strong {
                margin-bottom: 15px;
                font-size: 17px;
                color: black;
            }
        }
    }
}

.swal2-top-end {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    z-index: 99999;
}

.user-img {
    text-align: center;

    img {
        width: 200px;
        height: 200px;
        border-radius: 100%;
        object-fit: cover;
        margin-bottom: 20px;
    }
}

.MuiChip-label {
    padding-left: 0px;
    padding-right: 10px;
}

.MuiToolbar-root {
    .MuiButtonBase-root {
        border: none !important;
        color: transparent !important;
    }
    
    .see-more {
        .MuiButtonBase-root {
            color: #8c5d2f !important;
            border-bottom: 1px solid #8c5d2f !important;
        }
    }

    .MuiButtonBase-root:active {
        box-shadow: none !important;
    }
}

.MuiList-root .MuiListItemButton-root:hover .MuiListItemText-root p {
    color: white !important;
}

.country-select {
    width: 100%;

    input {
        width: 100%;
        padding-left: 0px;
        border: none;
        background: transparent;
        border-bottom: 1px solid #717170;
        outline: none;
        font-family: Mulish-SemiBold !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: black !important;
        opacity: 1;
    }

    .flag-dropdown {
        right: 0;
        left: auto;
    }
}

.MuiButtonBase-root {
    border: none !important;
}

.MuiPaper-root {
    .search-fields {
        .MuiOutlinedInput-root {
            background-color: transparent;
        }

        fieldset {
            border: 0px;
        }

        .MuiSelect-select {
            background: transparent;
            color: #656a7e;
            border-left: 2px solid #c3724e;
            border-radius: 0px;
            padding: 4px 31px;
            padding-left: 9px;
            text-transform: capitalize;
        }
    }
}

.search-fields {
    .MuiOutlinedInput-root {
        background-color: transparent;
    }

    fieldset {
        border: 0px;
    }

    .MuiSelect-select {
        background: transparent;
        color: #656a7e;
        border-left: 2px solid #c3724e;
        border-radius: 0px;
        padding: 4px 31px;
        padding-left: 9px;
    }
}

.variation-list-orders {
    .heading {
        margin-bottom: 10px;
        padding: 15px 20px;
        background: #f7f7f7;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
        }

        svg {
            cursor: pointer;
        }

        .btn-primary {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            border: none;
            font-family: 'FuturaPtBook' !important;
        }
    }

    .variation-padding {
        padding: 15px 20px;
        padding-right: 20px;
    }

    .sc-dlVxhl {
        .rdt_Table {
            .rdt_TableHead {
                .rdt_TableHeadRow {
                    background: #f7f7f7;
                    padding: 0px 20px;

                    .sc-hKwDye {
                        justify-content: flex-start !important;
                    }
                }
            }

            .rdt_TableBody {
                .rdt_TableRow {
                    padding: 10px 20px !important;
                }

                .ddMYzF,
                .fFBlx {
                    justify-content: flex-start !important;
                }

                .sc-hKwDye {
                    justify-content: flex-start !important;
                }
            }
        }
    }

    .brownBtn {
        background-color: #c3724e;
        border-color: #c3724e;
        margin: 5px;
        font-family: Mulish-SemiBold !important;
    }
}

.view-detail-popup {
    overflow: overlay;
    width: 1150px;

    .heading {
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding: 15px 20px;
        padding-right: 20px;
        background: #f7f7f7;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
        }

        svg {
            cursor: pointer;
        }

        .btn-primary {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            border: none;
            font-family: Mulish-SemiBold !important;
        }
    }

    .manufecturer {
        .cost-name {
            padding: 15px 20px;
            border-bottom: 1px solid #999999;
            padding-right: 20px;
        }

        .margin {
            margin: 0px 40px;
        }

        strong {
            color: black !important;
        }
        strong.reject {
            color: #df4949 !important;
        }
    }

    .price {
        .cost-name {
            padding: 25px 20px;
            background: #f7f7f7;
        }

        .margin {
            margin: 0px 40px;
        }

        P {
            color: black;
            font-size: 15px;
        }

        strong {
            color: black !important;
        }
    }

    .table {
        border-bottom: 1px solid #999999;
        margin-bottom: 0px;

        thead {
            border: none !important;
            background: #f7f7f7 !important;

            tr {
                th {
                    padding: 16px 20px !important;
                    border: none !important;
                }
            }
        }

        tbody {
            border: none !important;

            tr {
                border-bottom: 1px solid #99999963;

                td {
                    border: none !important;
                    padding: 16px 20px !important;

                    img {
                        width: 55px;
                        height: 55px;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    P {
                        margin: 0px;
                    }

                    input {
                        border: 1px solid #c4c4c4;
                        padding: 4px 4px;
                        border-radius: 8px;
                        outline: none;
                    }
                }
            }
        }
    }
}

.approval-detail-popup {
    width: 1150px;
    overflow: initial;

    .heading {
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding: 15px 20px;
        padding-right: 20px;
        background: #f7f7f7;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
        }

        svg {
            cursor: pointer;
        }

        .btn-primary {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            border: none;
            font-family: Mulish-SemiBold !important;
        }
    }

    .detail-btn {
        background: #4eaec3;
        border-radius: 6px;
        border: none;
        color: white;
        font-weight: 400;
        padding: 5px 20px;
        font-family: 'FuturaPtBook' !important;
    }

    .manufecturer {
        .cost-name {
            padding: 15px 20px;
            padding-right: 20px;
            border-bottom: 1px solid #99999963;
        }

        .margin {
            margin: 0px 40px;
        }

        strong {
            color: black !important;
        }
        strong.reject {
            color: #df4949 !important;
        }
    }

    .confirm-approval {
        padding: 10px 20px;

        p {
            color: black;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 6px;
            margin-top: 10px;
        }

        select {
            width: 100%;
            padding: 7px;
            border-radius: 8px;
            border: 1px solid #c4c4c4;
            outline: none;
        }

        textarea {
            width: 100%;
            padding: 7px;
            border-radius: 8px;
            border: 1px solid #c4c4c4;
            outline: none;
        }

        .approval-btn {
            margin: 20px;

            .reject {
                background-color: #e35757;
                color: white;
                box-shadow: none;
                margin-right: 20px;
                text-transform: initial;
                padding: 5px 30px;
                font-family: Mulish-SemiBold !important;
            }

            .approve {
                background-color: #57e376;
                color: white;
                box-shadow: none;
                text-transform: initial;
                padding: 5px 25px;
                font-family: Mulish-SemiBold !important;
            }
        }
    }

    .table {
        border-bottom: 1px solid #999999;

        thead {
            border: none !important;
            background: #f7f7f7 !important;

            tr {
                th {
                    padding: 16px 20px !important;
                    border: none !important;
                }
            }
        }

        tbody {
            border: none !important;

            tr {
                border-bottom: 1px solid #99999963;

                td {
                    border: none !important;
                    padding: 16px 20px !important;

                    img {
                        width: 55px;
                        height: 55px;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    P {
                        margin: 0px;
                    }

                    input {
                        border: 1px solid #c4c4c4;
                        padding: 4px 4px;
                        border-radius: 8px;
                        outline: none;
                    }
                }
            }
        }
    }
}

// register login styling
h1,
h2,
h3,
h4,
h5,
h6,
strong,
span,
button {
    font-family: Mulish-Bold !important;
}

p,
input,
em {
    font-family: Mulish-SemiBold !important;
}

@font-face {
    font-family: quigley;
    src: url(../fonts/Qwigley-Regular.ttf);
}

@font-face {
    font-family: Redressed-Regular;
    src: url(../fonts/Redressed-Regular.ttf);
}

@font-face {
    font-family: Mulish-Light;
    src: url(../fonts/Mulish-Light.ttf);
}

@font-face {
    font-family: Mulish-SemiBold !important;
    src: url(../fonts/Mulish-SemiBold.ttf);
}

@font-face {
    font-family: Mulish-Bold;
    src: url(../fonts/Mulish-Bold.ttf);
}

@font-face {
    font-family: FuturaPTMedium;
    src: url(../fonts/FuturaPTMedium.otf);
}

@font-face {
    font-family: 'FuturaPtBook';
    src: url(../fonts/FuturaPTBook.otf);
}

@font-face {
    font-family: Northwell;
    src: url(../fonts/NorthwellAlt.ttf);
}

@font-face {
    font-family: PangramRegular;
    src: url(../fonts/Pangram-Regular.otf);
}

.MuiBox-root {
    .MuiPaper-root {
        z-index: 30 !important;
    }
}

.MuiChip-clickable:hover {
    background-color: transparent !important;
    box-shadow: none !important;
}

foreignObject {
    overflow: hidden !important;
}

.MuiDivider-root {
    background: #9999997a !important;
}

.login-register {
    background-image: url(../images/login-back.png);
    background-repeat: no-repeat;
    background-position: 100% 16%;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Mulish-SemiBold !important;

    .login-back {
        background: #cbaf96f7;
        padding: 30px;
        font-family: Mulish-SemiBold !important;
        margin: 50px 0px;
    }

    .register-login {
        text-align: center;
        font-family: Mulish-SemiBold !important;

        .css-heg063-MuiTabs-flexContainer {
            justify-content: center;

            button {
                color: #dddddd !important;
                font-family: 'FuturaPTBook' !important;
                font-size: 30px !important;
                padding: 10px 35px;
            }
        }

        .css-k008qs {
            justify-content: center;

            button {
                color: #dddddd !important;
                font-family: 'FuturaPTBook' !important;
                font-size: 30px !important;
                padding: 5px 20px !important;
            }
        }

        .css-1i5jzbk-MuiTabs-indicator {
            background-color: transparent !important;
        }

        .css-19sojrt {
            background-color: transparent !important;
        }

        .css-19kzrtu {
            padding: 0px !important;
        }

        .css-19kzrtu {
            padding: 0px !important;
        }

        .css-1hvc0yk {
            color: white !important;
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
            border-radius: 0px;
            border: 1px solid #ffffff3b;
            border-bottom: 1px solid #a3a3a3;
        }

        .MuiFormControl-root {
            width: 100%;
            font-family: Mulish-SemiBold !important;
            font-size: 18px;

            .css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
                border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
            }

            .css-1480iag-MuiInputBase-root-MuiInput-root:after {
                border-bottom: 2px solid #c3724e !important;
            }

            .css-rmqpf6-MuiFormLabel-root-MuiInputLabel-root {
                color: #8c5d2f !important;
                font-size: 14px !important;
                font-family: Mulish-SemiBold !important;
            }

            .css-1hvc0yk {
                color: #8c5d2f !important;
                font-size: 14px !important;
                font-family: Mulish-SemiBold !important;
            }

            .MuiInput-input {
                padding: 10px 0 5px !important;
                font-family: 'FuturaPtBook' !important;
                font-size: 15px;
            }

            .MuiInput-input:focus {
                background-color: transparent !important;
            }

            .css-1f7bkfd {
                padding: 10px 0 5px !important;
            }

            .css-1478ka5-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
                color: #8c5d2f !important;
                margin-top: 10px !important;
            }

            .css-1478ka5-MuiFormLabel-root-MuiInputLabel-root {
                color: #8c5d2f !important;
                line-height: 3.4375em !important;
            }

            .css-1f7bkfd.Mui-focused {
                color: #8c5d2f !important;
                margin-top: 10px !important;
            }

            .css-1f7bkfd {
                color: #8c5d2f !important;
                line-height: 3.4375em !important;
            }
        }

        .MuiInputBase-root {
            width: 100%;
            padding-top: 10px !important;
            font-family: Mulish-SemiBold !important;
            font-size: 17px !important;
            margin-top: 16px !important;

            .MuiButtonBase-root {
                padding: 0px !important;

                ::placeholder {
                    color: red;
                }
            }
        }

        .MuiInput-input:focus {
            background-color: transparent !important;
        }

        .MuiInput-root:after {
            border-bottom: 2px solid #8c5d2f !important;
        }

        .MuiInput-root:before {
            border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
        }

        .css-ykhttz-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
            border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
        }

        .css-i44wyl:hover:not(.Mui-disabled):before {
            border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
        }

        .css-ykhttz-MuiInputBase-root-MuiInput-root:after {
            border-bottom: 2px solid #8c5d2f !important;
        }

        .css-i44wyl:after {
            border-bottom: 2px solid #8c5d2f !important;
        }

        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 5px 10px !important;
        }

        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
            color: #a3a3a3 !important;
            text-align: left !important;
            font-weight: 100 !important;
            padding: 5px 2px !important;
        }

        .css-i44wyl {
            padding: 5px 0px !important;
        }

        .css-i44wyl.MuiSelect-select {
            color: #a3a3a3 !important;
            text-align: left !important;
            font-weight: 100 !important;
            padding: 5px 2px !important;
        }

        .css-soy17v.Mui-focused {
            color: #8c5d2f !important;
        }

        .border-right {
            border-right: 2px solid #8c5d2f !important;
        }

        .css-13xc10z-MuiButtonBase-root-MuiTab-root.Mui-selected {
            color: white !important;
            padding: 10px 35px;
        }

        .css-59268d.Mui-selected {
            color: white !important;
        }

        .login-register-btn {
            width: 100%;
            margin-top: 30px;
            background: #8c5d2f;
            box-shadow: none;
            color: white;
            margin-bottom: 10px;
            font-family: Mulish-SemiBold !important;
        }

        .forget-password {
            color: white;
            margin-top: 20px;
            text-decoration: none;
            font-family: Mulish-SemiBold !important;
        }
    }
}

.register {
    input::placeholder,
    textarea::placeholder {
        font-family: Mulish-SemiBold !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: black !important;
        opacity: 1;
    }

    .MuiSelect-select > div {
        font-family: Mulish-SemiBold !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: black !important;
        opacity: 1;
    }

    .upload-heading p {
        font-family: Mulish-SemiBold !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: black !important;
        opacity: 1;
    }
}

.login {
    input::placeholder {
        font-family: Mulish-SemiBold !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: black !important;
        opacity: 1;
    }

    .forgotPassword a {
        font-family: Mulish-Semibold !important;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.02em;
        color: #999999;
        text-decoration: none;
    }
}

.mainDashboardMain {
    .heading {
        margin-bottom: 12px;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
            line-height: 1.7;
        }
    }

    .dashboardCard {
        padding: 20px;
        background: #ffe2e2;
        border-radius: 7px;
        align-items: end;

        .MuiTypography-p {
            font-family: Mulish-SemiBold !important;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #272727;
            margin-bottom: 19px;

            @media screen and (max-width: 992px) {
                font-size: 14px;
            }
        }

        .MuiTypography-h3 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 33px;
            color: #454545;

            @media screen and (max-width: 992px) {
                font-size: 25px;
            }
        }

        .textContainer {
            padding: 0px !important;
        }

        img {
            max-width: 65px;
            max-height: 78px;

            @media screen and (max-width: 992px) {
                max-width: 45px;
                max-height: 55px;
            }
        }
    }

    .dashboardCard.orders {
        background: #d1ffb5;
    }

    .dashboardCard.chats {
        background: #f6bdff;
    }

    .dashboardCard.process {
        background: #fff8bb;
    }

    .dashboardCard.delivery {
        background: #c2f0ff;
    }

    #chart {
        background: #ffffff;
        border-radius: 14px;
        padding: 20px;
        overflow: hidden !important;

        h4 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            color: #272727;
            text-align: center;
        }

        p {
            font-family: Mulish-Light !important;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            color: #272727;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .barChart {
        .apexcharts-canvas {
            margin: 0 auto !important;
        }
    }

    .top-selling {
        .table {
            border-collapse: separate;
            border-spacing: 0 8px;

            td {
                padding: 10px;
                vertical-align: middle;
                border-style: none !important;

                @media screen and (max-width: 575px) {
                    min-width: 180px;
                }
            }

            td:first-child {
                border-radius: 15px 0px 0px 15px;
                -moz-border-radius: 15px 0px 0px 15px;
                -webkit-border-radius: 15px 0px 0px 15px;
            }

            td:last-child {
                border-radius: 0 15px 15px 0;
                -moz-border-radius: 0 15px 15px 0;
                -webkit-border-radius: 0 15px 15px 0;
            }

            .table-row {
                background-color: white;
                padding: 10px;
                margin-bottom: 10px;
                border-style: none !important;

                .product-detail {
                    text-align: center;
                    border-style: none !important;

                    p {
                        color: #999999;
                        margin-bottom: 6px;
                        font-size: 16px;
                    }

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.designer-dashboard {
    .heading {
        margin-bottom: 12px;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
            line-height: 1.7;
        }
    }

    .dashboardCard {
        padding: 20px;
        background: #ffe2e2;
        border-radius: 7px;
        align-items: end;

        .MuiTypography-p {
            font-family: Mulish-SemiBold !important;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #272727;
            margin-bottom: 19px;

            @media screen and (max-width: 992px) {
                font-size: 15px;
            }
        }

        .MuiTypography-h3 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 33px;
            color: #454545;

            @media screen and (max-width: 992px) {
                font-size: 25px;
            }
        }

        .textContainer {
            padding: 0px !important;
        }

        img {
            max-width: 65px;
            max-height: 78px;

            @media screen and (max-width: 992px) {
                max-width: 65px;
                max-height: 78px;
            }
        }
    }

    .dashboardCard.orders {
        background: #d1ffb5;
    }

    .dashboardCard.chats {
        background: #e3e2ff;
    }

    .dashboardCard.process {
        background: #bbffeb;
    }

    .dashboardCard.delivery {
        background: #c2f0ff;
    }

    #chart {
        background: #ffffff;
        border-radius: 14px;
        padding: 20px;
        overflow: hidden !important;

        h4 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            color: #272727;
            text-align: center;
        }

        p {
            font-family: Mulish-SemiBold !important;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            color: #272727;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .barChart {
        .apexcharts-canvas {
            margin: 0 auto !important;
        }
    }
}

.manufecturer-dashboard {
    .heading {
        margin-bottom: 12px;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
            line-height: 1.7;
        }
    }

    .dashboardCard {
        padding: 20px;
        background: #fff7b2;
        border-radius: 7px;
        align-items: end;

        .MuiTypography-p {
            font-family: Mulish-SemiBold !important;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #272727;
            margin-bottom: 19px;
        }

        .MuiTypography-h3 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 33px;
            color: #454545;
        }

        .textContainer {
            padding: 0px !important;
        }

        img {
            max-width: 65px;
            max-height: 78px;
        }
    }

    .dashboardCard.orders {
        background: #d1ffb5;
    }

    .dashboardCard.chats {
        background: #e3e2ff;
    }

    .dashboardCard.process {
        background: #bbffeb;
    }

    .dashboardCard.delivery {
        background: #c2f0ff;
    }

    #chart {
        background: #ffffff;
        border-radius: 14px;
        padding: 20px;
        overflow: hidden !important;

        h4 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            color: #272727;
            text-align: center;
        }

        p {
            font-family: Mulish-SemiBold !important;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            color: #272727;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .barChart {
        .apexcharts-canvas {
            margin: 0 auto !important;
        }
    }
}

.homeMain {
    .carousel-item {
        img {
            height: 80vh;
            object-fit: cover;

            @media screen and (max-width: 767px) {
                height: 95vh;
            }

            @media screen and (max-width: 575px) {
                height: 70vh;
            }
        }
    }

    .carousel-indicators li {
        background-color: gray;
        border-radius: 50%;
        width: 8px;
        height: 8px;
    }

    .carousel-indicators li.active {
        background-color: #333;
        width: 12px !important;
        height: 12px !important;
    }

    .carousel-caption {
        position: absolute;
        right: auto;
        top: 50%;
        left: 50%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
        transform: translate(-50%, -50%);
        bottom: auto;
        width: 70%;

        h1 {
            color: white;
            font-family: 'FuturaPtBook' !important;
            font-size: 55px;
            font-weight: 100;

            @media screen and (max-width: 575px) {
                font-size: 20px;
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 23px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                font-size: 15px;
            }
        }

        button {
            color: $homeBrown;
            background-color: white;
            border: none;
            border-radius: 0px;
            width: 250px;
            box-shadow: none;
            padding: 10px;
            font-weight: 100;
            font-size: 16px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                font-size: 12px;
                width: 80%;
            }
        }

        .transparent-btn {
            background-color: transparent;
            border: 1px solid white !important;
            color: white;

            @media screen and (max-width: 767px) {
                margin-top: 20px;
            }
        }
    }

    .carousel-indicators {
        column-gap: 20px;

        button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            font-family: Mulish-SemiBold !important;
        }
    }

    .secondSection {
        padding: 5% 0%;

        @media screen and (max-width: 575px) {
            padding: 10% 0%;
        }

        background-color: $homeBackground;

        .col-md-4 {
            h1 {
                font-family: quigley;
                font-size: 35px;
                font-weight: 100;
            }

            h1 {
                font-family: 'FuturaPtBook' !important;
                color: $homeBrown;
                margin-top: 20px;
                margin-bottom: 10px;
            }

            p {
                font-family: 'FuturaPtBook' !important;
                color: $homeBrown;
                font-size: 19px;
                // height: 55px;
                // overflow: hidden;
            }
        }

        .MuiAvatar-circular {
            margin: 0 auto;
            height: 130px;
            width: 130px;
            background: transparent;
        }
    }

    .AsianTalentSection {
        background-image: url(../images/home/banner.jpg);
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: cover;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) {
            height: 50vh;
        }

        .floatingBox {
            text-align: center;

            h1 {
                color: white;
                font-family: 'FuturaPTBook' !important;
                font-size: 45px;
                font-weight: 100;

                @media screen and (max-width: 575px) {
                    font-size: 20px;
                }
            }

            p {
                color: white;
                margin-bottom: 20px;
                font-size: 23px;
                font-family: 'FuturaPtBook' !important;

                @media screen and (max-width: 575px) {
                    font-size: 16px;
                }
            }

            button {
                color: $homeBrown;
                background-color: white;
                border: none;
                border-radius: 0px;
                width: 50%;
                box-shadow: none;
                padding: 10px;
                font-weight: 100;
                line-height: 21px;
                font-size: 16px;
                font-family: 'FuturaPtBook' !important;

                @media screen and (max-width: 575px) {
                    font-size: 12px;
                    width: 80%;
                }
            }
        }
    }

    .ourStories {
        padding: 5% 0%;

        @media screen and (max-width: 575px) {
            padding: 10% 0%;
        }

        background-color: $homeBackground;

        h1 {
            color: $homeBrown;
            margin-bottom: 5px;
            font-family: 'FuturaPTBook' !important;
            font-size: 45px;
            font-weight: 100;
        }

        p {
            color: $homeBrown;
            margin-bottom: 50px;
            font-size: 21px;
            font-family: 'FuturaPtBook' !important;
        }

        .cardMain {
            border-radius: 0px;
            background-color: #f8dfcb;
            margin-bottom: 15px;

            h3 {
                color: $homeBrown;
                font-size: 20px;
                font-family: 'FuturaPtBook' !important;

                @media screen and (max-width: 575px) {
                    font-size: 15px;
                }
            }

            p {
                margin-bottom: 20px;
                font-size: 16px;
                height: 25px;
                overflow: hidden;

                @media screen and (max-width: 575px) {
                    font-size: 12px;
                }
            }

            button {
                padding: 0px;
                color: $homeBrown;
                font-weight: 500;
                border-bottom: 1px solid $homeBrown !important;
                border-radius: 0px;
                line-height: 23px;
                font-size: 16px;
                font-family: 'FuturaPtBook' !important;
                background: transparent !important;

                @media screen and (max-width: 575px) {
                    font-size: 14px;
                }
            }

            img {
                height: 312px;
                background: #8c5d2f;

                @media screen and (max-width: 1199px) {
                    height: 334px;
                }

                @media screen and (max-width: 575px) {
                    height: 150px;
                }
            }

            .MuiCardContent-root {
                flex-direction: column;
                display: flex;
                padding: 24px;
                align-items: baseline;
                justify-content: space-between;

                @media screen and (max-width: 575px) {
                    padding: 10px 20px;
                }
            }
        }

        .cardRight {
            .cardMain {
                display: flex;
                background-color: #f8dfcb;
                margin-bottom: 15px;

                @media screen and (max-width: 575px) {
                    flex-direction: column;
                }

                .MuiCardContent-root {
                    flex-direction: column;
                    display: flex;
                    padding: 10px 20px;
                    align-items: baseline;
                    justify-content: space-between;
                }

                h3 {
                    font-size: 16px;
                    font-family: 'FuturaPtBook' !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                p {
                    font-size: 13px;
                    font-family: 'FuturaPtBook' !important;
                    height: 55px;
                    overflow: hidden;

                    @media screen and (max-width: 991px) {
                        margin-bottom: 10px;
                    }
                }

                button {
                    font-size: 15px;
                }

                img {
                    width: 180px !important;
                    height: 143px !important;
                    object-fit: cover !important;
                    background: #8c5d2f;

                    @media screen and (max-width: 1199px) {
                        height: 150px !important;
                    }

                    @media screen and (max-width: 575px) {
                        width: 100% !important;
                    }
                }
            }
        }

        .storiesBtn {
            background-color: $homeBrown;
            border-radius: 0px;
            font-weight: 200;
            line-height: 30px;
            font-size: 16px;
            width: 64%;
            font-family: 'FuturaPtBook' !important;
        }
    }

    .ourCategory {
        padding: 5% 0%;

        @media screen and (max-width: 575px) {
            padding: 10% 0%;
        }

        background-color: $homeBackground;

        h1 {
            color: $homeBrown;
            margin-bottom: 5px;
            font-size: 45px;
            font-weight: 100;
            font-family: 'FuturaPTBook' !important;
        }

        p {
            color: $homeBrown;
            margin-bottom: 15px;
            font-size: 21px;
            font-family: 'FuturaPtBook' !important;
        }

        .single-img {
            div {
                padding: 20px 6px;
                overflow: hidden;

                img {
                    width: 235px;
                    object-fit: cover;
                    height: 300px;
                    background: white;
                }

                p {
                    color: #8c5d2f;
                    font-size: 18px;
                    margin: 20px 0px;
                }
            }
        }

        .slick-slider {
            text-align: center;

            @media screen and (max-width: 768px) {
                width: 95%;
                margin: 0 auto;
            }

            @media screen and (max-width: 575px) {
                width: 85%;
                margin: 0 auto;

                .slick-prev {
                    left: -25px;
                }

                .slick-next {
                    right: -25px;
                }
            }

            .slick-prev {
                left: -30px;
                background-image: url(../images/home/prev-btn.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 9px;
                top: 40%;

                @media screen and (max-width: 575px) {
                    left: -15px;
                }
            }

            .slick-next {
                right: -30px;
                background-image: url(../images/home/next-btn.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 9px;
                top: 40%;

                @media screen and (max-width: 575px) {
                    right: -15px;
                }
            }

            .slick-prev:before {
                content: '' !important;
                color: #8c5d2f !important;
                font-family: monospace !important;
                font-size: 35px !important;

                @media screen and (max-width: 575px) {
                    font-size: 25px !important;
                }
            }

            .slick-next:before {
                content: '' !important;
                color: #8c5d2f !important;
                font-family: monospace !important;
                font-size: 35px !important;

                @media screen and (max-width: 575px) {
                    font-size: 25px !important;
                }
            }

            img {
                width: 100%;
                object-fit: cover;
                height: 300px;
                background: white;
            }

            p {
                color: $homeBrown;
                font-size: 18px;
                margin: 20px 0px;
            }

            .slick-slide {
                padding: 20px 6px;
                overflow: hidden;
            }
        }

        .storiesBtn {
            background-color: $homeBrown;
            border-radius: 0px;
            font-weight: 200;
            line-height: 30px;
            font-size: 16px;
            width: 70%;
            font-family: 'FuturaPtBook' !important;
        }
    }

    .ourReviews {
        padding: 5% 0%;

        @media screen and (max-width: 575px) {
            padding: 5% 0%;
        }

        background-color: #d5bca3;

        h1 {
            color: white;
            margin-bottom: 20px;
            font-family: 'FuturaPTBook' !important;
            font-size: 45px;
            font-weight: 100;
        }

        .MuiAvatar-root {
            background: transparent;
            height: 150px;
            width: 150px;
            border-radius: 100%;
            margin: 20px auto;
            box-shadow: 0px 0px 10px 3px #0505052e;
        }

        .reviewCard {
            p {
                font-size: 15px;
                color: $homeBrown;
                margin-bottom: 10px;
                font-family: 'FuturaPtBook' !important;
                opacity: 0.7;
            }

            h1 {
                font-size: 35px;
                color: $homeBrown;
            }
        }
    }

    .socialMedia {
        padding: 5% 0%;

        @media screen and (max-width: 575px) {
            padding: 10% 0%;
        }

        background-color: $homeBackground;

        h1 {
            color: $homeBrown;
            margin-bottom: 50px;
            font-family: 'FuturaPTBook' !important;
            font-size: 45px;
            font-weight: 100;
        }

        img {
            width: 100%;
            height: 380px;
            object-fit: cover;
            padding: 10px;
            padding-bottom: 30px;
        }
    }

    .imgGallery {
        .content {
            position: relative;
            margin: auto;
            overflow: hidden;

            .content-overlay {
                background: rgb(0 0 0 / 28%);
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                -webkit-transition: all 0.4s ease-in-out 0s;
                -moz-transition: all 0.4s ease-in-out 0s;
                transition: all 0.4s ease-in-out 0s;
            }

            &:hover {
                .content-overlay {
                    opacity: 1;
                }

                .content-details {
                    top: 50%;
                    left: 50%;
                    opacity: 1;
                }
            }
        }

        .content-image {
            width: 100%;
            object-fit: cover;
            height: 230px;
        }

        .content-details {
            position: absolute;
            text-align: center;
            padding-left: 1em;
            padding-right: 1em;
            width: 100%;
            top: 50%;
            left: 50%;
            opacity: 0;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            -webkit-transition: all 0.3s ease-in-out 0s;
            -moz-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;

            h3 {
                color: #fff;
                font-weight: 100;
                letter-spacing: 0.15em;
                margin-bottom: 0.5em;
                font-size: 23px;
                font-family: 'FuturaPtBook' !important;
            }

            p {
                color: #fff;
                font-size: 13px;
                letter-spacing: 1px;
                margin-bottom: 20px;
                font-family: 'FuturaPtBook' !important;
            }

            button {
                border: none;
                padding: 3px 35px;
                line-height: 28px;
                font-size: 15px;
                color: #8c5d2f;
                font-family: 'FuturaPtBook' !important;
            }
        }

        .fadeIn-bottom {
            top: 80%;
        }

        .leftSection {
            .content-image {
                height: 476px;
                object-fit: cover;

                @media screen and (max-width: 1199px) {
                    height: 476px;
                }

                @media screen and (max-width: 575px) {
                    height: auto;
                }
            }
        }
    }
}

.footerMain {
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
        text-align: center;
    }

    background-image: url(../images/home/Footer.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    h3 {
        color: white;
        font-weight: 100 !important;
        font-size: 33px;
        font-family: 'FuturaPtBook' !important;
        margin: 0;
    }

    h4 {
        color: white;
        font-weight: 100;
        margin-bottom: 0;
        font-family: 'FuturaPTBook' !important;
        font-size: 45px;
    }

    .fotter-logo {
        img {
            width: 220px;
            cursor: pointer;

            @media screen and (max-width: 1200px) {
                width: 160px;
            }
        }

        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }

    .footer-links {
        @media screen and (max-width: 768px) {
            text-align: center;
            margin: 0px;
        }

        @media screen and (max-width: 575px) {
            margin-top: 15px;
        }

        ul {
            padding: 0px;
            list-style: none;

            li {
                color: white;
                line-height: 16px;
                font-size: 16px;
                margin-bottom: 20px;
                font-family: 'FuturaPtBook' !important;
                letter-spacing: 1px;
                cursor: pointer;
            }
        }

        .justify-content-end {
            @media screen and (max-width: 768px) {
                justify-content: center !important;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .text-center-big {
            text-align: center !important;
        }
    }

    .d-flex {
        p {
            background: white;
            width: 1px;
            font-family: 'FuturaPtBook' !important;
        }

        span {
            color: white;
            line-height: 14px;
            font-family: 'FuturaPtBook' !important;
            letter-spacing: 1px;
            cursor: pointer;
        }

        svg {
            color: white;
            margin: 10px 0px;
        }

        .social-icon {
            height: 30px;
            width: 30px;
            margin-bottom: 15px;
            object-fit: cover;
            margin-left: 5px;
        }
    }

    .copyRight {
        color: white;
        font-family: 'FuturaPtBook' !important;
        bottom: -8px;
        position: absolute;
        font-size: 15px;

        @media screen and (max-width: 768px) {
            position: relative;
            text-align: center;
        }

        svg {
            font-size: 15px;
        }
    }
}

.headerMain {
    background-color: #fff8f2;
    padding: 15px 0px;
    font-family: Mulish-SemiBold !important;

    img {
        width: 154px;
        height: 40px;
        cursor: pointer;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }

    .navbar-light {
        .navbar-nav {
            .nav-link {
                color: rgb(140 93 47 / 43%);
                padding: 10px 20px;
                font-family: 'FuturaPtBook' !important;
                font-size: 16px;
            }

            .nav-link.active {
                color: #8c5d2f !important;
                font-family: 'FuturaPtBook' !important;
            }
        }
    }

    .headerIcons {
        display: flex;

        @media screen and (max-width: 768px) {
            padding: 10px 8px;
        }

        img {
            width: 22px;
            height: 22px;
            margin-left: 13px;
        }

        .shop-now {
            padding: 1px 12px;
            background: transparent;
            border: 2px solid #c3724e;
            color: #c3724e;
            border-radius: 0px;
            text-transform: initial;
            font-size: 15px;
            box-shadow: none;
            font-weight: 600;
            font-family: 'FuturaPtBook' !important;
            margin-right: 3px;
        }

        .profile-dropdown {
            .nav-link {
                padding: 0px;

                img {
                    width: 25px;
                    height: 25px;
                    margin-left: 15px;
                    object-fit: cover;
                    border-radius: 100%;
                    border: 1px solid #8c5d2f;
                }
            }

            :after {
                display: none !important;
            }

            .dropdown-menu[data-bs-popper] {
                left: -80px !important;
                background: #e5d1c1;
                border: none;
                min-width: 8.2rem;
                top: 50px;
                flex-direction: column;

                .triangle {
                    width: 27px;
                    height: 24px;
                    background: #e5d1c1;
                    position: absolute;
                    transform: rotate(45deg);
                    right: 10px;
                    top: -9px;
                }
            }

            .MuiButtonBase-root {
                border: none !important;
                justify-content: flex-end;
                color: #8c5d2f;
                font-size: 13px;
                font-family: Mulish-SemiBold !important;
                padding: 8px 10px;
            }
        }
    }

    .headerInner {
        background-color: #fff8f2;

        h4 {
            color: $homeBrown;
        }

        .css-1t6c9ts {
            justify-content: center;

            button {
                font-family: 'FuturaPtBook';
                color: #e5d1c1;
            }

            button.active {
                font-family: FuturaPTMedium;
                color: $homeBrown;
            }
        }

        .css-2uchni {
            svg {
                margin-left: 10px;
                color: $homeBrown;
            }
        }
    }
}

.active-nav {
    color: #8c5d2f !important;
}

.slick-slider {
    text-align: center;

    @media screen and (max-width: 768px) {
        width: 95%;
        margin: 0 auto;
    }

    @media screen and (max-width: 575px) {
        width: 85%;
        margin: 0 auto;

        .slick-prev {
            left: -25px;
        }

        .slick-next {
            right: -25px;
        }
    }

    .slick-prev {
        left: -10px;
        background-image: url(../images/home/prev-btn.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 9px;
        top: 45%;

        @media screen and (max-width: 575px) {
            left: -15px;
        }
    }

    .slick-prev:hover,
    .slick-prev:focus {
        background-image: url(../images/home/prev-btn.svg) !important;
    }

    .slick-next:hover,
    .slick-next:focus {
        background-image: url(../images/home/next-btn.svg) !important;
    }

    .slick-next {
        right: -10px;
        background-image: url(../images/home/next-btn.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 9px;
        top: 45%;

        @media screen and (max-width: 575px) {
            right: -15px;
        }
    }

    .slick-prev:before {
        content: '' !important;
        color: #8c5d2f !important;
        font-family: monospace !important;
        font-size: 35px !important;

        @media screen and (max-width: 575px) {
            font-size: 25px !important;
        }
    }

    .slick-next:before {
        content: '' !important;
        color: #8c5d2f !important;
        font-family: monospace !important;
        font-size: 35px !important;

        @media screen and (max-width: 575px) {
            font-size: 25px !important;
        }
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 300px;
    }

    p {
        color: $homeBrown;
        font-size: 18px;
        margin: 20px 0px;
    }

    .slick-slide {
        padding: 20px 6px;
        overflow: hidden;
    }
}

.settingsMain {
    .password {
        column-gap: 5px;

        button {
            margin: 0px;
            width: 255px;
            border-radius: 8px;
        }
    }

    .seperator {
        width: 1px;
        background-color: #999999;
        height: 100%;
    }

    input,
    textarea,
    select {
        background-color: transparent !important;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .App {
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        background: transparent;

        .ant-card.ant-card-bordered {
            border-radius: 8px;
            background: transparent;
        }
    }

    .user-img {
        display: flex;
        align-items: center;
        column-gap: 20px;
        position: relative;

        img {
            height: 210px;
            border-radius: 15px;
            width: 250px;
        }

        .editPencil {
            border-radius: 0%;
            height: 10px;
            width: 10px;
            margin-left: 5px;
        }

        .uploadImg {
            position: absolute;
            width: 30px;
            height: 30px;
            margin-bottom: 0px;
            top: 0%;
            right: 2%;
            transform: translate(8px, -4px);
        }
    }

    .input-group {
        span {
            background-color: transparent;
            border: 1px solid #999999;
            border-right: 0px;
            padding-right: 0px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        input {
            border-left: 0px;
        }
    }

    .flagIcon {
        width: 30px;
        height: 20px;
    }

    h6,
    label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 38px;
        color: #292929;
        cursor: pointer;
    }

    .ant-col.ant-col-10 {
        max-width: 100%;
        flex: 100%;
    }

    .ant-col.ant-col-14 {
        display: none;
    }

    .ant-divider,
    .ant-btn {
        display: none;
    }

    .ant-btn-icon-only {
        display: inline-block;
    }

    .ant-card-body {
        padding-bottom: 0px;
    }

    .saveBtn {
        background: #999999;
        border-radius: 10px;
        font-family: Mulish-Light;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        text-transform: capitalize;
        color: #ffffff;
        border: none;
        width: 200px;
    }
}

.my-account {
    .password {
        column-gap: 5px;

        button {
            margin: 0px;
            width: 255px;
            border-radius: 8px;
        }
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: transparent;
        opacity: 1;
    }

    .seperator {
        width: 1px;
        background-color: #999999;
        height: 100%;
    }

    input,
    textarea,
    select {
        padding: 10px 0px;
        background: transparent;
        border: none;
        border-radius: 0px;
        width: 100%;
        margin-bottom: 20px;
        color: #8c5d2f;
        border-bottom: 1px solid #8c5d2f;

        &::placeholder {
            color: #8c5d2f;
        }

        &:focus {
            outline: none;
            box-shadow: none;
            background: none;
            border-color: #8c5d2f;
        }
    }

    .App {
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        background: transparent;

        .ant-card.ant-card-bordered {
            border-radius: 8px;
            background: transparent;
        }
    }

    .user-img {
        display: flex;
        align-items: center;
        column-gap: 20px;
        position: relative;

        img {
            border-radius: 50%;
            height: 150px;
            width: 150px;
        }

        .editPencil {
            border-radius: 0%;
            height: 10px;
            width: 10px;
            margin-left: 5px;
        }

        .uploadImg {
            position: absolute;
            width: 30px;
            height: 30px;
            margin-bottom: 0px;
            bottom: 9%;
            right: 22%;
            transform: translate(8px, -4px);
        }
    }

    .input-group {
        span {
            background-color: transparent;
            border: 1px solid #999999;
            border-right: 0px;
            padding-right: 0px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        input {
            border-left: 0px;
        }
    }

    .flagIcon {
        width: 30px;
        height: 20px;
    }

    h6,
    label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 38px;
        color: #292929;
    }

    .ant-col.ant-col-10 {
        max-width: 100%;
        flex: 100%;
    }

    .ant-col.ant-col-14 {
        display: none;
    }

    .ant-divider,
    .ant-btn {
        display: none;
    }

    .ant-btn-icon-only {
        display: inline-block;
    }

    .ant-card-body {
        padding-bottom: 0px;
    }

    .saveBtn {
        background: #999999;
        border-radius: 10px;
        font-family: Mulish-Light;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        text-transform: capitalize;
        color: #ffffff;
        border: none;
        width: 200px;
    }
}

.main-story {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    img {
        width: 100%;
        object-fit: fill;
        height: 400px;

        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
        }
    }

    .story-content {
        h5 {
            color: #8c5d2f;
            margin-bottom: 5px;
            font-family: 'FuturaPtBook' !important;
        }

        .date {
            color: #8c5d2f;
            font-size: 13px;
            font-family: 'FuturaPtBook' !important;
            opacity: 0.8;
        }

        .share-like {
            .share {
                color: #8c5d2f;
                margin-bottom: 10px;
                cursor: pointer;
            }
        }

        .description {
            color: #8c5d2f;
            margin: 40px 0px;
            line-height: 1.5;
            font-size: 18px;
            opacity: 0.8;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                margin: 20px 0px;
            }
        }

        .read-more {
            margin: 0px;

            button {
                background-color: transparent !important;
                color: #8c5d2f;
                border-bottom: 1px solid #8c5d2f !important;
                font-family: 'FuturaPtBook' !important;
                border-radius: 0px;
                padding: 0px;
                font-size: 1.25rem;
                font-weight: 500;
            }
        }
    }
}

.filter-bar {
    background-color: #fff8f2;
    border-top: 1px solid #8c5d2f !important;
    border-bottom: 1px solid #8c5d2f !important;
    padding: 10px 0px;

    .search-box {
        width: fit-content;
        height: fit-content;
        position: relative;

        @media screen and (max-width: 575px) {
            margin-bottom: 10px;
        }
    }

    .input-search {
        height: 50px;
        width: 50px;
        border-style: none;
        padding: 10px;
        font-size: 18px;
        letter-spacing: 2px;
        outline: none;
        border-radius: 25px;
        transition: all 0.5s ease-in-out;
        padding-right: 40px;
        color: #8c5d2f;
        font-family: 'FuturaPtBook' !important;
    }

    .input-search::placeholder {
        color: #8c5d2f;
        font-size: 13px;
        letter-spacing: 2px;
        font-family: 'FuturaPtBook' !important;
    }

    .btn-search {
        width: 50px;
        height: 50px;
        border-style: none;
        font-weight: bold;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        right: 0px;
        color: #8c5d2f;
        background-color: #fff8f2;
        pointer-events: painted;
        font-size: 15px;
        padding: 0px;
        font-family: 'FuturaPtBook' !important;
    }

    svg {
        margin-right: 7px;
        font-size: 23px;
    }

    .btn-search:focus ~ .input-search {
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid #8c5d2f !important;
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
    }

    .input-search:focus {
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid #8c5d2f !important;
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
    }

    .MuiFormControl-root {
        margin: 0px;

        .MuiOutlinedInput-root {
            width: 100% !important;
            background-color: transparent;

            .MuiSelect-select {
                width: 100% !important;
                padding: 8px 0px;
                padding-right: 40px;
                color: #8c5d2f;
                border-radius: 4px;
                background-color: transparent;

                em {
                    font-style: normal;
                    font-family: Mulish-Bold !important;
                    color: #8c5d2f;
                    font-size: 15px;
                    padding-right: 7px;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }

            svg {
                top: 6px;
                color: #8c5d2f;
            }
        }

        .MuiMenuItem-root {
            font-family: 'FuturaPtBook' !important;
            font-style: normal !important;
        }
    }

    .MuiPopover-paper {
        border-radius: 0px;

        .MuiList-root {
            background: #fff8f2;
            border: none;
            min-width: 9rem;
            display: flex;
            flex-direction: column;

            .Mui-selected {
                background-color: #fff8f2;
            }
        }
    }

    p {
        margin-bottom: 0px;
        color: #8c5d2f;
        font-size: 15px;
        font-weight: 800 !important;
        font-family: 'FuturaPtBook' !important;
    }
}

.MuiPopover-paper {
    border-radius: 0px !important;

    .MuiList-root {
        background: #fff8f2 !important;
        border: none !important;
        min-width: 9rem !important;
        display: flex !important;
        flex-direction: column !important;
        text-transform: capitalize;
        font-family: Mulish-Light !important;

        .MuiMenuItem-root {
            font-family: Mulish-SemiBold !important;

            .Mui-selected {
                background-color: #fff8f2 !important;

                svg {
                    color: #8c5d2f !important;
                }
            }
        }
    }
}

.all-stories {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    @media screen and (min-width: 1920px) {
        padding: 85px 0px;
    }

    .story-content {
        margin-bottom: 50px;

        img {
            width: 100%;
            object-fit: cover;
            height: 250px;
            margin-bottom: 20px;
        }

        h5 {
            color: #8c5d2f;
            margin-bottom: 3px;
            font-size: 18px;
            font-family: 'FuturaPTMedium' !important;
        }

        .date {
            color: #8c5d2f;
            font-size: 12px;
            margin-bottom: 0px;
            opacity: 0.8;
            font-family: 'FuturaPtBook' !important;
        }

        .description {
            color: #8c5d2f;
            margin: 5px 0px;
            line-height: 1.5;
            font-size: 16px;
            opacity: 0.8;
            font-family: 'FuturaPtBook' !important;
            height: 45px;
            overflow: hidden;
        }

        .read-more {
            margin-top: 5px;
            margin-bottom: 0px;

            button {
                background-color: transparent !important;
                color: #8c5d2f;
                border-bottom: 1px solid #8c5d2f !important;
                border-radius: 0px;
                padding: 0px;
                font-family: 'FuturaPTMedium' !important;
                font-size: 15px;
            }
        }
    }

    .see-more {
        margin: 0px;

        button {
            background-color: transparent !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
            border-radius: 0px;
            padding: 0px;
            font-size: 19px;
            font-family: 'FuturaPTMedium' !important;
        }
    }
}

.see-more {
    margin: 20px 0px;

    button {
        background-color: transparent !important;
        color: #8c5d2f;
        border-bottom: 1px solid #8c5d2f !important;
        border-radius: 0px;
        padding: 0px;
        font-size: 19px;
        font-family: 'FuturaPTMedium' !important;
    }
}

.stories-details {
    background-color: #fff8f2;
    padding: 5% 0%;
    border-bottom: 1px solid #8c5d2f !important;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    .back-btn {
        margin-top: 5px;
        margin-bottom: 0px;

        button {
            background-color: transparent !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
            border-radius: 0px;
            padding: 0px;
            font-size: 14px;
            justify-content: flex-start;
            min-width: 50px !important;
        }

        svg {
            font-size: 13px;
        }
    }

    h5 {
        color: #8c5d2f;
        font-family: 'FuturaPTMedium' !important;
        font-size: 34px;
        // font-style: italic;
        font-weight: 100;
        margin-bottom: 20px;
        margin-top: 20px;

        @media screen and (max-width: 575px) {
            font-size: 40px;
        }
    }

    img {
        width: 100%;
        height: 450px;
        object-fit: cover;
    }

    .small {
        color: #8c5d2f;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 35px;
        font-family: 'FuturaPtBook' !important;
        opacity: 0.8;
    }

    .main-heading {
        color: #8c5d2f;
        margin-bottom: 20px;
        font-size: 19px;
        opacity: 1;
        font-family: 'FuturaPtBook' !important;

        @media screen and (max-width: 575px) {
            font-size: 15px;
        }
    }

    p {
        color: #8c5d2f;
        margin-bottom: 20px;
        font-size: 18px;
        font-family: 'FuturaPtBook' !important;
        opacity: 0.8;

        @media screen and (max-width: 575px) {
            font-size: 15px;
        }
    }

    .share {
        margin-top: 40px;

        .share-heading {
            color: #8c5d2f;
            margin-bottom: 5px;
            font-size: 16px;
            font-family: 'FuturaPtBook' !important;
            opacity: 1;

            @media screen and (max-width: 575px) {
                font-size: 15px;
            }
        }

        .social {
            svg {
                color: #8c5d2f;
                cursor: pointer;
            }
        }
    }
}

.single-img {
    .stories-crousel {
        background-color: #fff8f2;
        padding: 0px 5px;
        text-align: center;

        @media screen and (max-width: 575px) {
            padding: 10% 0px;
        }

        img {
            width: 300px;
            height: 300px;
            object-fit: cover;
        }

        h5 {
            color: #8c5d2f;
            font-family: 'FuturaPTBook' !important;
            font-size: 45px;
            font-weight: 100;
            margin-bottom: 25px;

            @media screen and (max-width: 575px) {
                font-size: 40px;
                margin-bottom: 0px;
            }
        }

        h6 {
            width: 100%;
            color: #8c5d2f;
            margin-top: 10px;
            margin-bottom: 0px;
            font-size: 17px;
            line-height: inherit;
            width: 100%;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                font-size: 15px;
            }
        }

        small {
            color: #8c5d2f;
            margin-bottom: 0px;
            font-size: 13px;
            font-family: 'FuturaPtBook' !important;
            opacity: 0.8;

            @media screen and (max-width: 575px) {
                font-size: 12px;
            }
        }

        .shop-carousel {
            // overflow: hidden;
        }

        .slick-slider {
            text-align: center;

            @media screen and (max-width: 768px) {
                width: 95%;
                margin: 0 auto;
            }

            @media screen and (max-width: 575px) {
                width: 85%;
                margin: 0 auto;

                .slick-prev {
                    left: -25px;
                }

                .slick-next {
                    right: -25px;
                }
            }

            .slick-prev {
                left: -20px;
                background-image: url(../images/home/prev-btn.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 9px;
                top: 40%;

                @media screen and (max-width: 575px) {
                    left: -15px;
                }
            }

            .slick-next {
                right: -20px;
                background-image: url(../images/home/next-btn.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                width: 9px;
                top: 40%;

                @media screen and (max-width: 575px) {
                    right: -15px;
                }
            }

            .slick-prev:before {
                content: '' !important;
                color: #8c5d2f !important;
                font-family: monospace !important;
                font-size: 35px !important;

                @media screen and (max-width: 575px) {
                    font-size: 25px !important;
                }
            }

            .slick-next:before {
                content: '' !important;
                color: #8c5d2f !important;
                font-family: monospace !important;
                font-size: 35px !important;

                @media screen and (max-width: 575px) {
                    font-size: 25px !important;
                }
            }

            img {
                width: 100%;
                object-fit: cover;
                height: 300px;
                background: white;
            }

            p {
                color: $homeBrown;
                font-size: 18px;
                margin: 20px 0px;
            }

            .slick-slide {
                padding: 20px 6px;
                // overflow: hidden;
            }
        }
    }
}

.stories-crousel {
    background-color: #fff8f2;
    padding: 5% 0%;
    text-align: center;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    img {
        width: 300px;
        height: 300px;
        object-fit: cover;
        cursor: pointer;
    }

    h5 {
        color: #8c5d2f;
        font-family: 'FuturaPTBook' !important;
        font-size: 45px;
        font-weight: 100;
        margin-bottom: 25px;

        @media screen and (max-width: 575px) {
            font-size: 40px;
            margin-bottom: 0px;
        }
    }

    h6 {
        color: #8c5d2f;
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 17px;
        line-height: inherit;
        cursor: pointer;
        // width: 300px;
        font-family: 'FuturaPtBook' !important;

        @media screen and (max-width: 575px) {
            font-size: 15px;
        }
    }

    small {
        color: #8c5d2f;
        margin-bottom: 0px;
        font-size: 13px;
        font-family: 'FuturaPtBook' !important;
        opacity: 0.8;

        @media screen and (max-width: 575px) {
            font-size: 12px;
        }
    }

    .shop-carousel {
        // overflow: hidden;
    }

    .slick-slider {
        text-align: center;

        @media screen and (max-width: 768px) {
            width: 95%;
            margin: 0 auto;
        }

        @media screen and (max-width: 575px) {
            width: 85%;
            margin: 0 auto;

            .slick-prev {
                left: -25px;
            }

            .slick-next {
                right: -25px;
            }
        }

        .slick-prev {
            left: -20px;
            background-image: url(../images/home/prev-btn.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            width: 9px;
            top: 40%;

            @media screen and (max-width: 575px) {
                left: -15px;
            }
        }

        .slick-next {
            right: -20px;
            background-image: url(../images/home/next-btn.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            width: 9px;
            top: 40%;

            @media screen and (max-width: 575px) {
                right: -15px;
            }
        }

        .slick-prev:before {
            content: '' !important;
            color: #8c5d2f !important;
            font-family: monospace !important;
            font-size: 35px !important;

            @media screen and (max-width: 575px) {
                font-size: 25px !important;
            }
        }

        .slick-next:before {
            content: '' !important;
            color: #8c5d2f !important;
            font-family: monospace !important;
            font-size: 35px !important;

            @media screen and (max-width: 575px) {
                font-size: 25px !important;
            }
        }

        img {
            width: 100%;
            object-fit: cover;
            height: 300px;
            background: white;
        }

        p {
            color: $homeBrown;
            font-size: 18px;
            margin: 20px 0px;
        }

        .slick-slide {
            padding: 20px 6px;
            overflow: hidden;
        }
    }
}

.shop-filter-head {
    background-color: #fff8f2;

    h5 {
        color: #8c5d2f;
        font-family: 'FuturaPTBook' !important;
        font-size: 45px;
        font-weight: 100;
        padding-top: 35px;
        padding-bottom: 25px;
        margin: 0px;
        text-align: center;

        @media screen and (max-width: 575px) {
            font-size: 40px;
            margin-bottom: 0px;
        }
    }
}

.shop-filter-bar {
    background-color: #fff8f2;
    border-top: 1px solid #8c5d2f;
    border-bottom: 1px solid #8c5d2f;
    padding: 10px 0px;
    position: relative;

    .search-box {
        width: fit-content;
        height: fit-content;
        position: relative;

        @media screen and (max-width: 575px) {
            margin-bottom: 10px;
        }
    }

    .input-search {
        height: 50px;
        width: 50px;
        border-style: none;
        padding: 10px;
        font-size: 18px;
        letter-spacing: 2px;
        outline: none;
        border-radius: 25px;
        transition: all 0.5s ease-in-out;
        padding-right: 40px;
        color: #8c5d2f;
        font-family: 'FuturaPtBook' !important;
        background: #fff8f2;
    }

    .input-search::placeholder {
        color: #8c5d2f;
        font-size: 13px;
        letter-spacing: 2px;
        font-family: 'FuturaPtBook' !important;
    }

    .btn-search {
        width: 50px;
        height: 50px;
        border-style: none;
        font-weight: bold;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        right: 0px;
        color: #8c5d2f;
        background-color: #fff8f2;
        pointer-events: painted;
        font-size: 15px;
        padding: 0px;
        font-family: 'FuturaPtBook' !important;
    }

    svg {
        margin-right: 7px;
        font-size: 23px;
    }

    .btn-search:focus ~ .input-search {
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid #8c5d2f !important;
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
    }

    .input-search:focus {
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid #8c5d2f !important;
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
    }

    .MuiFormControl-root {
        margin: 0px;

        .MuiOutlinedInput-root {
            width: 100% !important;
            background-color: transparent;

            .MuiSelect-select {
                width: 100% !important;
                padding: 8px 0px;
                padding-right: 40px;
                color: #8c5d2f;
                border-radius: 4px;
                margin-bottom: 15px;
                background-color: transparent;
                font-size: 18px;

                em {
                    font-style: normal;
                    font-family: 'FuturaPtBook' !important;
                    color: #8c5d2f;
                    font-size: 18px;
                    padding-right: 7px;
                    font-weight: bold;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }

            svg {
                top: 6px;
                color: #8c5d2f;
            }
        }

        .MuiMenuItem-root {
            font-family: 'FuturaPtBook' !important;
            font-style: normal !important;
        }
    }

    p {
        margin-bottom: 0px;
        color: #8c5d2f;
        font-size: 15px;
        font-weight: 800 !important;
        font-family: 'FuturaPtBook' !important;
        cursor: pointer;
    }
}

.shop-cards {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    @media screen and (min-width: 1920px) {
        padding: 85px 0px;
    }

    .shop-card {
        margin-bottom: 50px;

        img {
            width: 100%;
            object-fit: cover;
            height: 320px;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .shop-card-content {
            h5 {
                color: #8c5d2f;
                margin-bottom: 3px;
                font-size: 18px;
                font-family: 'FuturaPTMedium' !important;
            }

            .price {
                color: #8c5d2f;
                font-size: 17px;
                margin-bottom: 0px;
                opacity: 0.8;
                font-family: 'FuturaPtBook' !important;
            }

            .description {
                color: #8c5d2f;
                margin: 5px 0px;
                line-height: 1.5;
                font-size: 15px;
                opacity: 0.8;
                font-family: 'FuturaPtBook' !important;
            }

            .heart {
                .MuiCheckbox-root {
                    padding: 0px;
                    color: #8c5d2f !important;
                }

                .Mui-checked {
                    color: #8c5d2f !important;
                }
            }
        }
    }

    .see-more {
        margin: 0px;

        button {
            background-color: transparent !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
            border-radius: 0px;
            padding: 0px;
            font-size: 19px;
            font-family: 'FuturaPTMedium' !important;
        }
    }
}

.shop-card-thumbnail {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    .back-btn {
        margin-top: 5px;
        margin-bottom: 0px;

        button {
            background-color: transparent !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
            border-radius: 0px;
            padding: 0px;
            font-size: 14px;
            justify-content: flex-start;
            min-width: 50px !important;
        }

        svg {
            font-size: 13px;
        }
    }

    .cart-images {
        margin-top: 30px;

        .cart-details {
            @media screen and (max-width: 768px) {
                padding-top: 45px;
            }

            h5 {
                color: #8c5d2f;
                font-size: 22px;
                font-family: 'FuturaPTMedium' !important;
                margin-bottom: 10px;
            }

            h4 {
                color: #8c5d2f;
                font-family: 'FuturaPTMedium' !important;
                font-size: 34px;
                // font-style: italic;
                margin-bottom: 10px;
            }

            .price {
                color: #8c5d2f;
                font-size: 22px;
                margin-bottom: 5px;
                font-family: 'FuturaPtBook' !important;
            }

            .other-detail {
                color: #8c5d2f;
                font-size: 16px;
                font-family: 'FuturaPtBook' !important;
            }

            .description {
                color: #8c5d2f;
                font-size: 18px;
                opacity: 0.8;
                font-family: 'FuturaPtBook' !important;
                margin-bottom: 13px;
                // height: 60px;
                max-height: 150px;
                overflow-y: auto;
            }

            .share-like {
                margin-bottom: 15px;

                .share {
                    color: #8c5d2f;
                    margin-right: 10px;
                    cursor: pointer;
                }

                .MuiCheckbox-root {
                    color: #8c5d2f;
                    padding: 0px;
                }
            }

            .select-clr {
                .MuiRadio-root {
                    padding: 0px;
                    margin-bottom: 10px;
                    margin-right: 8px;
                }

                .text {
                    color: #8c5d2f;
                    margin-bottom: 0px;
                    margin-top: 3px;
                    font-size: 15px;
                    font-family: 'FuturaPtBook' !important;
                }
            }

            .buy-cart-btn {
                margin-top: 20px;

                .buy-now {
                    background-color: #8c5d2f;
                    border-radius: 0px;
                    font-weight: 200;
                    line-height: 30px;
                    font-family: 'FuturaPtBook' !important;
                    color: white;
                    width: 50%;
                    margin-right: 10px;
                }

                .add-cart {
                    background-color: #fff8f2;
                    border: 1px solid #8c5d2f !important;
                    border-radius: 0px;
                    font-weight: 200;
                    line-height: 30px;
                    font-family: 'FuturaPtBook' !important;
                    color: #8c5d2f;
                    width: 50%;
                    margin-right: 10px;
                }
            }
        }

        .thumbnail-imgs {
            .image-gallery {
                .image-gallery-content {
                    .image-gallery-slides {
                        .image-gallery-slide {
                            .image-gallery-image {
                                width: 100%;
                                object-fit: cover;
                                max-height: 540px;
                                min-height: 540px;

                                @media screen and (max-width: 575px) {
                                    width: 100%;
                                    object-fit: cover;
                                    max-height: 350px;
                                    min-height: 350px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .thumbnail-imgs {
            .fullscreen {
                @media screen and (max-width: 525px) {
                    .image-gallery-slide-wrapper.left {
                        width: 100% !important;
                    }

                    .image-gallery-thumbnails {
                        display: none;
                    }
                }

                .image-gallery-slides {
                    .image-gallery-slide {
                        .image-gallery-image {
                            max-height: 100vh !important;
                            object-fit: contain !important;
                            height: 100vh;
                        }
                    }
                }
            }
        }

        .image-gallery-thumbnails .image-gallery-thumbnail-image {
            object-fit: cover;
        }
    }
}

.shop-designer {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0px;

    @media screen and (max-width: 767px) {
        height: auto;
        padding: 25px 0px;
    }

    .shop-designer-content {
        text-align: center;

        h5 {
            color: white;
            margin-bottom: 15px;
            font-size: 23px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                font-size: 17px;
            }
        }

        h3 {
            color: white;
            margin-bottom: 15px;
            font-family: 'FuturaPtBook' !important;
            letter-spacing: 3px;
            font-size: 35px;

            @media screen and (max-width: 575px) {
                font-size: 25px;
            }
        }

        p {
            color: white;
            font-size: 17px;
            margin-bottom: 20px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                font-size: 13px;
            }
        }

        button {
            color: $homeBrown;
            background-color: white;
            border: none;
            border-radius: 0px;
            width: 100%;
            box-shadow: none;
            padding: 10px;
            font-weight: 100;
            line-height: 21px;
            margin-bottom: 20px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 575px) {
                font-size: 12px;
            }
        }

        .designer-images {
            img {
                width: 23.5%;
                height: 95px;
                object-fit: cover;
                cursor: pointer;
            }
        }
    }
}

.shop-rating-review {
    background-color: #fff8f2;
    // padding-top: 5%;

    @media screen and (max-width: 575px) {
        padding: 10% 0px;
    }

    .reviews {
        padding-bottom: 40px;
        padding-top: 40px;
        border-top: 1px solid #e5d6c7;
        border-bottom: 1px solid #e5d6c7;

        @media screen and (max-width: 575px) {
            padding-bottom: 10px;
        }

        .review-section {
            @media screen and (max-width: 575px) {
                margin-bottom: 25px;
            }

            h6 {
                color: #8c5d2f;
                font-size: 20px;
                margin: 0px;
                padding-right: 7px;
                font-family: 'FuturaPtBook' !important;
            }

            small {
                color: #8c5d2f;
                font-size: 14px;
                font-weight: 500;
                font-family: 'FuturaPtBook' !important;
            }

            .star {
                margin-top: 10px;

                .MuiRating-root {
                    color: #8c5d2f !important;

                    label {
                        padding: 0px;
                        padding-right: 10px;
                    }
                }
            }

            p {
                margin-top: 10px;
                color: #8c5d2f;
                font-family: 'FuturaPtBook' !important;
            }
        }

        .brownBtn {
            background-color: #8c5d2f;
            border-radius: 0px;
            color: white;
            font-family: 'FuturaPtBook' !important;
            padding: 7px 35px;

            @media screen and (max-width: 575px) {
                margin-top: 25px;
            }
        }

        .rating-checkbox {
            margin-bottom: 10px;

            .MuiCheckbox-root {
                padding: 0px;
                color: #8c5d2f;
                padding-right: 12px;
            }

            p {
                margin-bottom: 0px;
                color: #8c5d2f;
                font-family: 'FuturaPtBook' !important;
                font-size: 16px;
            }

            .progressbar {
                width: 40%;
                padding: 0px 12px;

                .progress {
                    background-color: #f8dfcb !important;
                    border-radius: 0px;
                    height: 18px;

                    .progress-bar {
                        background-color: #d5bca3 !important;
                    }
                }
            }
        }
    }

    .feedbacks {
        .customer-feedback {
            padding: 40px 0px;
            border-bottom: 1px solid #e5d6c7;

            .img-name {
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 100%;
                    object-fit: cover;
                }

                h5 {
                    color: #8c5d2f;
                    margin-top: 20px;
                    margin-left: 10px;
                    font-family: 'FuturaPtBook' !important;
                }
            }

            .feedback {
                .star {
                    margin-top: 10px;

                    .MuiRating-root {
                        color: #8c5d2f !important;

                        label {
                            padding: 0px;
                            padding-right: 10px;
                        }
                    }

                    p {
                        color: #8c5d2f;
                        margin-bottom: 0px;
                        font-family: 'FuturaPtBook' !important;
                        font-size: 15px;
                    }
                }

                h5 {
                    color: #8c5d2f;
                    margin-top: 25px;
                    font-family: 'FuturaPTMedium' !important;
                }

                .description {
                    color: #8c5d2f;
                    margin-top: 20px;
                    width: 50%;
                    font-size: 15px;
                    opacity: 0.8;
                    font-family: 'FuturaPtBook' !important;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }

                .see-more {
                    margin: 0px !important;
                    padding: 0px !important;

                    button {
                        background-color: transparent !important;
                        color: #8c5d2f;
                        border-bottom: 1px solid #8c5d2f !important;
                        border-radius: 0px;
                        padding: 0px;
                        font-size: 15px;
                        font-family: 'FuturaPTMedium' !important;
                    }
                }

                .thumb {
                    .MuiCheckbox-root {
                        padding: 0px;
                        color: #8c5d2f;
                        border-radius: 100%;
                    }

                    h5 {
                        margin: 0px;
                        margin-left: 10px;
                        margin-right: 4px;
                        font-size: 17px;
                        font-family: 'FuturaPTMedium' !important;
                    }

                    P {
                        color: #8c5d2f;
                        margin-bottom: 0px;
                        font-family: 'FuturaPtBook' !important;
                    }
                }
            }

            .see-more {
                margin: 0px;
                padding-top: 40px;

                button {
                    background-color: transparent !important;
                    color: #8c5d2f;
                    border-bottom: 1px solid #8c5d2f !important;
                    border-radius: 0px;
                    padding: 0px;
                    font-size: 17px;
                }
            }
        }
    }
}

.billing-address {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    h5 {
        color: #8c5d2f;
        font-size: 60px;
        font-family: 'FuturaPTBook' !important;
    }

    .billing-box {
        padding: 20px;
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff8f2;

        h6 {
            color: #8c5d2f;
            font-size: 19px;
            margin-bottom: 15px;
            font-family: 'FuturaPTMedium' !important;
        }

        .input-field {
            label {
                color: #8c5d2f !important;
                font-size: 17px;
                margin-top: 15px;
                font-weight: 500;
                font-family: 'FuturaPTMedium' !important;
            }

            .form-control {
                border: none;
                background-color: transparent;
                border-bottom: 1px solid #8c5d2f;
                border-radius: 0px;
                outline: 0;
                width: 100%;
                padding-left: 0px;
                font-family: 'FuturaPtBook' !important;
            }

            :focus {
                box-shadow: none;
            }
        }

        p {
            color: #8c5d2f;
            margin-bottom: 2px;
            font-size: 15px;
            opacity: 0.8;
            font-family: 'FuturaPtBook' !important;
        }

        .brownBtn {
            background-color: #8c5d2f;
            border: #8c5d2f;
            border-radius: 0px;
            color: white;
            font-family: 'FuturaPtBook' !important;
            padding: 7px 35px;
        }

        .edit-btn {
            text-align: end;

            @media screen and (max-width: 575px) {
                text-align: start;
                margin-top: 10px;
            }
        }
        .MuiOutlinedInput-root {
            background: transparent;
            border: none;
            border-radius: 0px;
            width: 100%;
            margin-bottom: 20px;
            font-family: 'FuturaPTMedium' !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f;
            padding: 10px;
        }
        .MuiInputBase-root .MuiSelect-select {
            background: transparent;
            border-radius: 0px;
            color: #8c5d2f;
            font-size: 15px;
            text-align: left;
            padding: 0px;
            padding-left: 0px;
            padding-right: 0px;
        }
        .MuiSelect-icon {
            height: 40px !important;
            right: 0px;
        }
        fieldset {
            border: 0;
        }
    }
}

.product-selected {
    background-color: #fff8f2;

    h5 {
        color: #8c5d2f;
        font-size: 19px;
        margin-bottom: 15px;
        font-family: 'FuturaPTMedium' !important;
    }
    p {
        color: #8c5d2f;
        font-size: 16px;
        font-family: 'FuturaPTMedium' !important;
    }

    .products {
        padding: 30px 0px;

        @media screen and (max-width: 575px) {
            padding: 10px 0px;
        }

        .product-img {
            @media screen and (max-width: 575px) {
                margin-bottom: 30px;
            }

            .MuiCheckbox-root {
                margin-right: 20px;
                vertical-align: top;
                padding: 0px;
                color: #8c5d2f;

                @media screen and (max-width: 575px) {
                    margin-right: 4px;
                }

                .PrivateSwitchBase-input {
                    margin-top: 5px;
                }
            }

            .Mui-checked {
                color: #8c5d2f !important;
            }

            img {
                width: 200px;
                height: 200px;
                object-fit: cover;
                margin-right: 15px;

                @media screen and (max-width: 575px) {
                    width: 145px;
                    height: 145px;
                }
            }

            p {
                color: #8c5d2f;
                margin-bottom: 5px;
                font-family: 'FuturaPtBook' !important;
                font-size: 15px;
            }
        }

        p {
            color: #8c5d2f;
            margin-bottom: 5px;
            font-family: 'FuturaPtBook' !important;
            font-size: 15px;
        }

        h5 {
            color: #8c5d2f;
            font-size: 15px;
            margin-bottom: 0px;
            margin-top: 2px;
        }

        .counter {
            p {
                margin: 0px 15px;
                font-family: 'FuturaPtBook' !important;
            }

            button {
                color: #8c5d2f;
                min-width: 10px !important;
                line-height: 0;
                padding: 0px 10px;
            }
        }
    }
}

.payment-method {
    background-color: #fff8f2;
    padding-bottom: 5%;
    padding-top: 1%;

    @media screen and (max-width: 575px) {
        padding-bottom: 10%;
        padding-top: 3%;
    }

    .promotional-code {
        padding-bottom: 10px;

        h5 {
            color: #8c5d2f;
            font-size: 17px;
            margin-bottom: 0px;
            font-family: 'FuturaPtBook' !important;
        }

        svg {
            color: #8c5d2f;
        }
    }

    .discount-total {
        border-bottom: 1px solid #e5d6c7;
        border-top: 1px solid #e5d6c7;
        padding: 25px 0px;

        h5 {
            color: #8c5d2f;
            font-size: 17px;
            margin-bottom: 10px;
            font-family: 'FuturaPtBook' !important;
        }

        .MuiFormControlLabel-root {
            @media screen and (max-width: 575px) {
                margin-bottom: 10px;
            }
        }

        .MuiFormGroup-root {
            width: 100%;

            .MuiRadio-root {
                padding: 0px;
                padding-right: 5px;
            }

            .Mui-checked {
                color: #8c5d2f !important;
            }

            svg {
                width: 13px;
                height: 13px;
            }

            .MuiTypography-root {
                color: #8c5d2f;
                font-size: 17px;
                font-family: 'FuturaPtBook' !important;
            }

            .Mui-disabled {
                color: #969696 !important;
            }
        }
    }

    .place-order-btn {
        text-align: center;
        margin-top: 40px;

        .place-order {
            background-color: #8c5d2f;
            border-radius: 0px;
            font-weight: 200;
            line-height: 30px;
            font-family: 'FuturaPtBook' !important;
            color: white;
            padding: 7px 35px;
        }
    }
}

.MuiDrawer-paper {
    background-color: #fff8f2;
    overflow-y: hidden !important;
}

.shopping-cart {
    background-color: #fff8f2;
    width: 300px;

    .cart-heading {
        h5 {
            color: #8c5d2f;
            margin-bottom: 0px;
            font-size: 20px;
            font-family: 'FuturaPTMedium' !important;
        }
    }

    .MuiTabPanel-root {
        overflow-y: scroll;
        height: 80vh;
        padding-bottom: 222px !important;
    }

    .padding-cart {
        padding: 20px;
    }

    .tabs {
        .MuiTabs-flexContainer {
            justify-content: center !important;
            margin-bottom: 15px;

            .Mui-selected {
                color: #8c5d2f;
            }

            button {
                padding: 10px 20px;
                min-height: 0px;
                font-family: 'FuturaPtBook' !important;
            }

            .border-right {
                border-right: 1px solid #8c5d2f !important;
            }
        }

        .products {
            .cart-product {
                padding-bottom: 35px;

                .cart-img {
                    img {
                        width: 75px;
                        height: 80px;
                        object-fit: cover;
                        margin-right: 7px;
                    }

                    p {
                        color: #8c5d2f;
                        margin-bottom: 0px;
                        font-size: 13px;
                        font-family: 'FuturaPtBook' !important;
                    }

                    h5 {
                        color: #8c5d2f;
                        margin-bottom: 0px;
                        font-size: 14px;
                        font-family: 'FuturaPtBook' !important;
                    }
                }

                .price {
                    p {
                        color: #8c5d2f;
                        margin-bottom: 0px;
                        font-size: 12px;
                    }
                }

                .cart-counter {
                    margin-top: 5px;

                    .see-more {
                        margin: 0px;

                        button {
                            background-color: transparent !important;
                            color: #8c5d2f;
                            border-bottom: 1px solid #8c5d2f !important;
                            border-radius: 0px;
                            padding: 0px;
                            font-size: 14px;
                            font-family: 'FuturaPtBook' !important;
                            justify-content: left;
                            min-width: 50px;
                        }
                    }

                    .counter {
                        p {
                            margin: 0px 15px;
                            font-family: 'FuturaPtBook' !important;
                        }

                        button {
                            color: #8c5d2f;
                            min-width: 10px !important;
                        }
                    }
                }
            }

            .clear-cart {
                background-color: #fff8f2;
                border: 1px solid #8c5d2f !important;
                border-radius: 0px;
                font-weight: 200;
                line-height: 30px;
                font-family: 'FuturaPtBook' !important;
                color: #8c5d2f;
                width: 100%;
            }
        }
    }

    .checkout {
        background-color: #e5d1c1;
        padding: 20px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        // height: 222px;

        .promotional-code {
            padding-bottom: 10px;
            border-bottom: 1px solid #8c5d2f;

            h5 {
                color: #8c5d2f;
                font-size: 15px;
                margin-bottom: 0px;
                font-family: 'FuturaPTMedium' !important;
            }

            svg {
                color: #8c5d2f;
                font-size: 17px;
            }
        }

        .discount {
            padding-top: 15px;

            h5 {
                color: #8c5d2f;
                font-size: 15px;
                margin-bottom: 0px;
                font-family: 'FuturaPTMedium' !important;
            }
        }

        .checkout-btn {
            margin-top: 20px;
            background-color: #8c5d2f;
            border-radius: 0px;
            font-weight: 200;
            line-height: 30px;
            font-family: 'FuturaPtBook' !important;
            color: white;
            width: 100%;
        }
    }
}

.promotional-code-popup {
    padding: 20px;
    background-color: #fff8f2;
    min-width: 600px;

    @media screen and (max-width: 575px) {
        min-width: 200px;
        padding: 20px;
    }

    h1 {
        font-size: 17px;
        color: #8c5d2f;
        font-family: 'FuturaPTMedium' !important;
    }

    input {
        border: none;
        background-color: transparent;
        border-bottom: 1px solid #8c5d2f;
        padding-bottom: 10px;
        outline: 0;
        width: 100%;
        padding-top: 30px;
        font-family: 'FuturaPtBook' !important;
    }

    ::placeholder {
        color: #8c5d2f;
        font-family: 'FuturaPtBook' !important;
    }

    svg {
        cursor: pointer;
        color: #8c5d2f;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .apply-btn {
        margin-top: 60px;
        background-color: #8c5d2f;
        border-radius: 0px;
        font-weight: 200;
        line-height: 30px;
        padding: 6px 30px;
        font-family: 'FuturaPtBook' !important;
        color: white;
    }
}

.following {
    background-color: #fff8f2;
    padding-top: 5%;

    @media screen and (max-width: 575px) {
        padding-top: 10%;
    }

    .following-heading {
        text-align: center;

        h5 {
            font-family: 'FuturaPTBook' !important;
            font-size: 45px;
            color: #8c5d2f;
        }
    }

    .tabs {
        .MuiTabs-flexContainer {
            justify-content: center !important;
            margin-bottom: 45px;

            .Mui-selected {
                color: #8c5d2f;
            }

            button {
                font-family: 'FuturaPTMedium' !important;
                padding: 10px 20px;
                min-height: 0px;
                font-size: 17px;
            }

            .border-right {
                border-right: 1px solid #8c5d2f !important;
            }
        }
    }
}

.designer-tab {
    .designer {
        border-bottom: 1px solid #cbb29a;
        padding: 3% 0px;

        .designer-img {
            img {
                object-fit: cover;
                cursor: pointer;
                height: 210px;
                // border-radius: 15px;
                width: 250px;

                @media screen and (max-width: 575px) {
                    margin-bottom: 20px;
                }
            }

            h5 {
                color: #8c5d2f;
                font-family: 'FuturaPTMedium' !important;
            }

            p {
                color: #8c5d2f;
                font-size: 15px;
                font-family: 'FuturaPTBook' !important;
                line-height: 1.7;
                margin-bottom: 0px;
            }
        }

        .product-desc {
            img {
                width: 100%;
                height: 210px;
                object-fit: cover;
                cursor: pointer;

                @media screen and (max-width: 575px) {
                    margin-bottom: 20px;
                }
            }

            h5 {
                color: #8c5d2f;
                font-family: 'FuturaPTMedium' !important;
                margin-bottom: 0px;
            }

            p {
                color: #8c5d2f;
                font-size: 15px;
                font-family: 'FuturaPTBook' !important;
                line-height: 1.7;
                margin-bottom: 0px;
            }
        }

        .following-btn {
            text-align: end;

            @media screen and (max-width: 575px) {
                margin-top: 15px;
                text-align: left;
            }

            .f-btn {
                background-color: #8c5d2f;
                border-radius: 0px;
                font-weight: 200;
                line-height: 30px;
                font-family: 'FuturaPtBook' !important;
                color: white;
                padding: 2px 40px;
            }

            .remove {
                background-color: transparent;
                border-bottom: 1px solid #8c5d2f !important;
                border-radius: 0px;
                font-weight: 200;
                line-height: 30px;
                font-family: 'FuturaPtBook' !important;
                color: #8c5d2f;
                padding: 0px;
                min-width: 0px;
            }
        }
    }

    .see-more {
        margin: 0px;
        margin-top: 40px;

        button {
            background-color: transparent !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
            border-radius: 0px;
            padding: 0px;
            font-size: 19px;
            font-family: 'FuturaPTMedium' !important;
        }
    }
}

.Profileinfo {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    .name {
        h1 {
            color: #8f613c;
            font-family: 'FuturaPTBook' !important;
            margin-bottom: 18px;
            font-size: 30px;
        }
    }

    background-color: rgb(255 248 242);

    .profileimg {
        text-align: center;

        img {
            width: 180px;
            text-align: center;
            height: 180px;
            object-fit: cover;
            border-radius: 100%;
            cursor: pointer;
        }

        .editbutton {
            margin-top: 19px;

            Button {
                width: 100%;
                box-shadow: none;
                border-radius: 0px !important;
                text-transform: capitalize;
                color: #8c5d2f;
                background: transparent !important;
                border: 2px solid #8c5d2f !important;
            }
        }

        .reset {
            color: #8c5d2f;
            margin-top: 13px !important;
            cursor: pointer;

            span {
                font-size: 14px;
                padding-bottom: 5px;
                border-bottom: 1px solid #8c5d2f;
            }
        }
    }

    .points {
        display: flex;
        justify-content: center;

        h6 {
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f;
            font-family: 'futuranormal';
            padding-bottom: 16px;
            cursor: pointer;
        }

        span {
            border-left: 2px solid #8c5d2f;
            font-weight: 550;
            font-size: 0.9rem;
            font-family: 'futuramedium';
            color: #8f6134;
        }
    }

    .profile_data {
        h6 {
            color: #8c5d2f;
            font-family: 'futuranormal';
            font-size: 0.9rem;
            font-weight: 600;
            margin-bottom: 0px;
            margin-right: 4px;
            line-height: 1.7;
            display: flex;
        }

        span {
            color: #8c5d2f;
            opacity: 0.8;
            line-height: 1.7;

            input {
                background: transparent;
                border: 0px;
                outline: 0;
                color: #8c5d2f;

                &::placeholder {
                    color: #8c5d2f;
                }
            }
        }

        .editbutton {
            span {
                color: #8c5d2f;
                font-size: 0.9rem !important;
                font-family: 'futuranormal';
                font-weight: normal;
            }
        }
    }

    .css-1aquho2-MuiTabs-indicator {
        position: absolute;
        height: 0px !important;
        bottom: 0;
        width: 100%;
        background-color: none !important;
    }

    .dialog-buttons {
        .no {
            width: 100%;
            box-shadow: none;
            border-radius: 0px !important;
            text-transform: capitalize;
            color: #8c5d2f;
            background: transparent !important;
            box-shadow: none !important;
            border: 2px solid #8c5d2f;
        }

        .yes {
            width: 100%;
            box-shadow: none;
            border-radius: 0px !important;
            text-transform: capitalize;
            background-color: #8c5d2f !important;
            border: 2px solid #8c5d2f;
            box-shadow: none !important;

            span {
                color: #fff;
            }
        }
    }

    .imgcontent-details {
        h2 {
            font-size: 1rem;
            color: #8c5d2f;
            font-size: 1rem;
            color: #8c5d2f;
        }

        span {
            font-size: 1rem;
            color: #8c5d2f !important;
        }
    }

    .MuiTabs-fixed {
        .MuiTabs-indicator {
            background-color: #fff8f2 !important;
            display: none !important;
        }

        .MuiButtonBase-root {
            opacity: 1;
            text-transform: capitalize !important;
            font-weight: 600 !important;
            border: 0;
            color: #8c5d2f !important;
            font-family: 'FuturaPtBook' !important;
            font-weight: 100 !important;
            margin-top: 4px;
            width: 17%;
            font-size: 16px;
        }

        .MuiButtonBase-root.Mui-selected {
            box-shadow: 0px 3px 6px #00000033;
            border-bottom: none !important;
            font-weight: 600 !important;
            font-family: 'FuturaPTMedium' !important;
            background: #fff8f2;
        }
    }

    .wallet {
        h5 {
            font-weight: 550;
            margin-bottom: 17px;
            font-family: 'FuturaPtMedium' !important;
            color: #8c5d2f;
            margin-bottom: 25px;
            font-size: 18px;

            span {
                font-size: 20px;
            }
        }

        .wallet-button {
            justify-content: end;

            .span {
                color: #fff !important;
            }

            .newcardbutton {
                span {
                    color: #fff !important;
                }
            }
        }

        p {
            color: #8f6134;
            font-family: 'FuturaPtBook' !important;
        }

        .addwallet {
            border-bottom: 2px solid #d4bea9;
            padding-bottom: 10px;

            p {
                font-weight: 600;
            }
        }

        h6 {
            color: #8f6134;
            font-family: 'FuturaPtMedium' !important;
            color: #8f6134;
            margin-bottom: 10px !important;
        }

        h5 {
            font-weight: 550;
        }
    }

    .order-button {
        background-color: #fff8f2 !important;
        border-radius: 0% !important;
        padding: 0px !important;
        border: 2px solid #8c5d2f !important;
        text-transform: capitalize !important;
        color: #8c5d2f !important;
        box-shadow: none !important;
        /* width: 100% !important; */
        width: 140px;
        font-size: 12px;
        position: absolute;
        padding: 6px !important;
        top: 95px;
        right: 0;

        @media screen and (max-width: 767px) {
            position: relative;
            top: 0px;
            margin: 20px 0px !important;
        }
    }

    .MuiTimeline-positionRight {
        padding: 6px 0px !important;
    }

    .MuiTimelineItem-positionRight {
        &:before {
            content: '';
            -webkit-flex: 0 !important;
            -ms-flex: 0 !important;
            flex: 0 !important;
            padding: 0px !important;
        }
    }

    .css-v6mo1y-MuiTimelineDot-root {
        background-color: #9c836a !important;
    }

    .css-1ami7lv-MuiTimelineConnector-root {
        width: 2px;
        background-color: #8c5d2f !important;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .timeline {
        .date {
            color: #8c5d2f;
            font-size: 0.7rem;
            padding: 6px 8px;
        }

        .time {
            color: #8c5d2f;
            font-size: 0.7rem;
            padding: 6px 8px;
            color: #8c5d2f;
            opacity: 0.8;
        }

        .order {
            color: #8c5d2f;
            font-size: 0.7rem;
            padding: 6px 8px;
            color: #8c5d2f;
            opacity: 0.8;
        }
    }

    .order-img {
        img {
            height: 160px;
            width: 100%;
            object-fit: cover;
        }
    }

    .myvouchers {
        padding: 20px 0px;
        border-bottom: 2px solid #8f6134;
    }

    .myorders {
        .MuiAccordion-region > div {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .MuiAccordion-root {
            background-color: transparent !important;

            .MuiAccordionSummary-root {
                min-height: 40px;

                .MuiAccordionSummary-content {
                    margin: 0px 0px;
                }
            }
        }

        p {
            color: #8f6134;
            font-size: 0.9rem;
            opacity: 0.9;
            font-family: 'FuturaPtBook' !important;
            margin: 0px;

            svg {
                font-size: 18px;
            }

            span {
                font-family: 'FuturaPTMedium' !important;
                opacity: 1 !important;
            }
        }

        h5 {
            color: #8c5d2f !important;
            margin-bottom: 17px;
            font-family: 'FuturaPTMedium' !important;

            @media screen and (max-width: 767px) {
                margin-top: 20px;
            }
        }

        .MuiButtonBase-root.my-order {
            min-height: 30px !important;
        }

        .my-order {
            svg {
                color: #8f613c;
                font-size: 18px;
                margin: 0px 6px;
            }

            span {
                font-weight: 100;
                font-size: 0.8rem;
            }

            .MuiAccordionSummary-content {
                margin: 0px;
            }

            p {
                margin-bottom: 0px;
            }
        }

        .shipping-free {
            padding: 20px 0px;
            margin-bottom: 20px;
            text-align: end;
            border-top: 2px solid #d4bea9;

            p {
                font-size: 17px;
                opacity: 0.7;
                margin-bottom: 10px;
            }

            h5 {
                font-size: 1rem;
                opacity: 0.9;
            }
        }
    }

    .address {
        p {
            color: #8f6134;
            font-family: 'FuturaPtBook' !important;
        }

        h5 {
            font-weight: 550;
            margin-bottom: 17px;
            font-family: 'FuturaPtMedium' !important;
            color: #8c5d2f;
            margin-bottom: 25px;
            font-size: 18px;
        }

        .newaddressbutton {
            .newaddressbutton {
                background-color: #8c5d2f !important;
                border-radius: 0% !important;
                text-transform: capitalize !important;
                color: #fff !important;
                width: 100% !important;
                box-shadow: none !important;
            }
        }

        .address-button {
            justify-content: end;
        }
    }

    .voucher {
        padding-bottom: 25px;

        h5 {
            font-weight: 550;
            margin-bottom: 17px;
            font-family: 'FuturaPtMedium' !important;
            color: #8c5d2f;
            margin-bottom: 25px;
            font-size: 18px;
        }

        p {
            color: #8f6134;
            font-family: 'FuturaPtBook' !important;
        }

        .voucher-button {
            .newcardbutton {
                background-color: #8c5d2f !important;
                border-radius: 0% !important;
                text-transform: capitalize !important;
                color: #fff !important;
                width: 100% !important;
                margin: 0px !important;
                box-shadow: none !important;
            }
        }

        .newcardbutton {
            span {
                color: #fff !important;
            }
        }
    }

    .borderb {
        border-bottom: 2px solid #d4bea9;
    }

    .stylist {
        h5 {
            font-weight: 550;
            margin-bottom: 17px;
            font-family: 'FuturaPtMedium' !important;
            color: #8c5d2f;
            margin-bottom: 25px;
            font-size: 18px;
        }

        p {
            color: #8f6134;
            font-family: 'FuturaPtBook' !important;
        }

        .stylist-button {
            .newcardbutton {
                background-color: #8c5d2f !important;
                border-radius: 0% !important;
                text-transform: capitalize !important;
                color: #fff !important;
                width: 100% !important;
                margin: 0px !important;
                box-shadow: none !important;
            }
        }

        .newcardbutton {
            span {
                color: #fff !important;
            }
        }

        .date {
            padding-bottom: 15px;
        }
    }

    .newaddressbutton {
        background-color: #8c5d2f !important;
        border-radius: 0% !important;
        text-transform: capitalize !important;
        font-size: 13px !important;
        color: #fff !important;
        width: 160px !important;
        box-shadow: none !important;

        span {
            color: #fff !important;
        }
    }

    .newcardbutton {
        background-color: #8c5d2f !important;
        border-radius: 0% !important;
        text-transform: capitalize !important;
        font-size: 13px !important;
        color: #fff !important;
        width: 100% !important;
        box-shadow: none !important;
    }

    .MuiTabPanel-root {
        padding: 24px;
        box-shadow: 0px 3px 6px #00000033;
        box-shadow: azure;
    }

    .edit {
        span {
            border-bottom: 1px solid rgb(146 98 52);
            color: rgb(146 98 52);
        }
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        background-color: #fff8f2 !important;
        color: #8c5d2f !important;
        padding: 0px 5px;
        text-transform: capitalize;
        font-family: 'futuramedium' !important;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
        border-radius: 0px;
        width: 30%;
    }

    .MuiTabs-flexContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        flex-wrap: wrap;
        display: flex !important;
        justify-content: space-around;
    }

    .model-content {
        opacity: 90%;
    }

    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 0px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);

        .modal-title {
            img {
                width: 120px;
            }
        }
    }

    .modal {
        .modal-body {
            img {
                height: 120px;
            }

            p {
                text-align: center !important;
                padding: 30px 0px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .Profileinfo {
        .profileimg {
            .editbutton {
                Button {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .Profileinfo {
        .newaddressbutton {
            margin: 20px 0px !important;
        }

        .newcardbutton {
            margin: 20px 0px !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .Profileinfo {
        .stylist {
            .stylist-button {
                .newcardbutton {
                    background-color: #8c5d2f !important;
                    border-radius: 0% !important;
                    text-transform: capitalize !important;
                    color: #fff !important;
                    display: flex !important;
                    width: 50% !important;
                    margin: 10px 0px !important;
                    box-shadow: none !important;
                }
            }
        }

        .voucher {
            .voucher-button {
                .newcardbutton {
                    background-color: #8c5d2f !important;
                    border-radius: 0% !important;
                    text-transform: capitalize !important;
                    color: #fff !important;
                    width: 50% !important;
                    display: flex !important;
                    margin: 10px 0px !important;
                    box-shadow: none !important;
                }
            }
        }

        .wallet {
            .wallet-button {
                justify-content: start;
                margin-top: 15px;
            }
        }

        .address {
            .address-button {
                justify-content: start;
                margin-top: 15px;
            }
        }

        .newaddressbutton {
            margin: 20px 0px !important;
        }

        .newcardbutton {
            margin: 20px 0px !important;
        }

        .dialog-buttons {
            .no {
                width: 60%;
                display: flex;
                margin: 20px auto;
            }

            .yes {
                width: 60%;
                display: flex;
                margin: 20px auto;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .Profileinfo {
        .myorders {
            p {
                color: #8f6134;
                font-size: 1rem;
                margin-top: 10px;
            }

            .shipping-free {
                padding: 0px;
            }
        }

        .newaddressbutton {
            margin: 8px 0px !important;
        }

        .newcardbutton {
            margin: 8px 0px !important;
        }

        .timeline {
            .date {
                color: #8c5d2f;
                font-size: 0.6rem;
                padding: 6px 5px;
                font-weight: 500;
            }

            .time {
                color: #8c5d2f;
                font-size: 0.6rem;
                padding: 6px 5px;
                font-weight: 500;
            }

            .order {
                color: #8c5d2f;
                font-size: 0.6rem;
                padding: 6px 5px;
                font-weight: 500;
            }
        }

        .profile_data {
            h6 {
                color: #8c5d2f;
                font-family: 'futuranormal';
                font-size: 0.8rem;
                font-weight: 600;
            }
        }
    }

    h5 {
        color: #8f6134;
        font-size: 1rem;
    }
}

.stylist {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    h1 {
        text-align: center;
        color: rgb(140 93 47) !important;
        font-size: 60px;
        font-family: 'FuturaPTBook' !important;
    }

    .see-more {
        margin: 0px;
        margin-top: 35px;

        button {
            background-color: transparent !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
            border-radius: 0px;
            padding: 0px;
            font-size: 19px;
            font-family: 'FuturaPTMedium' !important;
        }
    }

    .stylist-cards {
        .stylish-card {
            margin-top: 60px;

            img {
                width: 100%;
                height: 400px;
                cursor: pointer;
            }

            .stylist-cards-data {
                padding-top: 20px;

                h2 {
                    color: #8c5d2f !important;
                    font-size: 21px;
                    font-family: 'FuturaPTMedium' !important;
                }

                p {
                    color: #8c5d2f !important;
                    margin-top: 10px;
                    font-size: 16px;
                    opacity: 0.8;
                    font-family: 'FuturaPtBook' !important;
                }
            }

            .Follow-button {
                Button {
                    background-color: #8c5d2f !important;
                    color: #fff !important;
                    padding: 3px 15px;
                    font-size: 13px;
                    border-radius: 0px !important;
                    font-family: 'FuturaPtBook' !important;
                }

                svg {
                    color: #fff !important;
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .stylist-cards {
        img {
            width: 100%;
            height: 260px;
        }
    }
}

@media screen and (max-width: 376px) {
    .stylist-cards {
        img {
            width: 100%;
            height: 310px;
        }
    }
}

@media screen and (max-width: 320px) {
    .stylist-cards {
        img {
            width: 100%;
            height: 290px;
        }
    }
}

.banner {
    background: url(../../views/pages/images/banner.jpg);
    width: 100%;
    background-size: cover;
    height: 500px;
    background-repeat: no-repeat;

    h2 {
        font-family: 'FuturaPTBook' !important;
        font-size: 45px;
        text-align: center;
        color: #fff !important;
        margin-bottom: 10px;
    }

    p {
        color: #fff !important;
        font-family: 'FuturaPtBook' !important;
        text-align: center;
        font-size: 17px;
        margin-bottom: 35px;
    }

    .Book-button {
        margin-top: 5px;
        width: 49%;

        Button {
            color: #8c5d2f !important;
            background-color: #fff !important;
            padding: 6px 10px;
            width: 100%;
            font-size: 17px;
            border-radius: 0px !important;
            font-family: 'FuturaPtBook' !important;
        }
    }

    .Cart-button {
        margin-top: 5px;
        width: 49%;

        Button {
            background-color: transparent !important;
            color: #fff !important;
            padding: 6px 10px;
            width: 100%;
            border: 1px solid #fff !important;
            font-size: 16px;
            border-radius: 0px !important;
            font-family: 'FuturaPtBook' !important;
        }
    }

    .Follow-button {
        Button {
            background-color: #8c5d2f !important;
            color: #fff !important;
            padding: 6px 10px;
            display: flex;
            width: 100%;
            margin: auto;
            font-size: 17px;
            border-radius: 0px !important;
            font-family: 'FuturaPtBook' !important;
        }

        .icon {
            color: #fff !important;
            font-size: 19px;
        }
    }
}

.featured {
    background-color: #fff8f2;
    padding: 5% 0%;
    border-bottom: 1px solid #e5d6c7;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    h1 {
        text-align: center;
        color: rgb(140 93 47) !important;
        font-size: 60px;
        font-family: 'FuturaPTBook' !important;
    }
}

.carousel-control-prev-icon {
    background: url(../../views/pages/images/previousicon.svg);
    filter: none !important;
    height: 50px;
    width: 50px;
}

.carousel-control-next-icon {
    background: url(../../views/pages/images/nexticon.svg);
    filter: none !important;
    height: 50px;
    width: 50px;
}

.featured-cards {
    .featured-cards-data {
        padding: 20px;
        background-color: #d5bca3;

        .favourite {
            color: rgb(140 93 47);
            font-family: 'FuturaPtBook' !important;
        }

        .share {
            color: rgb(140 93 47);
            font-family: 'FuturaPtBook' !important;
            cursor: pointer;
        }

        h2 {
            color: #8c5d2f !important;
            font-size: 18px;
            font-family: 'FuturaPtBook' !important;
            margin-bottom: 5px;
        }

        h6 {
            color: #8c5d2f !important;
            font-size: 12px;
            font-family: 'FuturaPtBook' !important;
            opacity: 0.8;
        }

        p {
            color: #8c5d2f !important;
            font-size: 15px;
            text-align: start;
            font-family: 'FuturaPtBook' !important;
            opacity: 0.8;
        }

        .heart {
            .MuiCheckbox-root {
                padding: 0px;
                color: #8c5d2f !important;
            }

            .Mui-checked {
                color: #8c5d2f !important;
            }
        }
    }

    img {
        width: 100%;
        height: 300px;
    }
}

.carousel-indicators [data-bs-target] {
    background-color: white !important;
}

@media screen and (max-width: 993px) {
    .Book-button {
        display: flex;
        width: 39%;
    }

    .Cart-button {
        display: flex;
        width: 39%;
    }
}

@media screen and (max-width: 769px) {
    .featured-cards {
        img {
            height: 260px;
        }
    }

    .Book-button {
        margin: auto;
        display: flex;
        width: 80%;
        margin-top: 10px;
    }

    .Cart-button {
        margin: auto;
        display: flex;
        width: 80%;
        margin-top: 10px;
    }
}

@media screen and (max-width: 376px) {
    .featured-cards {
        img {
            width: 100%;
            height: 310px;
        }
    }
}

@media screen and (max-width: 320px) {
    .featured-cards {
        img {
            width: 100%;
            height: 290px;
        }
    }

    .Book-button {
        margin: auto;
        display: flex;
        width: 80%;
        margin-top: 10px;
    }

    .Cart-button {
        margin: auto;
        display: flex;
        width: 80%;
        margin-top: 10px;
    }
}

.mega-menu {
    background-color: #f8dfcb;
    padding: 30px 0px;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    top: 70px;
    display: none;
    opacity: 1;
    transition: 0.3s all ease-in;

    h5 {
        color: #8c5d2f !important;
    }

    p {
        color: #8c5d2f !important;
    }

    .heading {
        margin-bottom: 0px;

        h5 {
            font-size: 15px;
            line-height: 19px;
            font-family: 'FuturaPTMedium' !important;
            letter-spacing: 1px;
            opacity: 0.7;
            font-weight: 600;
            margin-bottom: 0px;
        }
    }

    .contents {
        p {
            opacity: 0.8;
            font-family: 'FuturaPtBook' !important;
            font-size: 0.9rem;
            margin-bottom: 10px;
            font-weight: 500;
        }

        margin-top: 40px;
    }

    .storage-contents {
        p {
            opacity: 0.8;
            font-family: 'FuturaPtBook' !important;
            font-size: 0.9rem;
            margin-bottom: 10px;
        }

        margin-top: 25px;
    }

    img {
        width: 100%;
        height: 85px;
        padding: 2px;
        object-fit: cover;
    }
}

.megaMenuParent {
    &:hover .mega-menu {
        display: block;
    }
}

@media screen and (max-width: 992px) {
    .mega-menu {
        .heading {
            padding: 10px 0px;
        }

        .contents {
            margin-top: 0px;
        }

        .storage-contents {
            margin-top: 0px;
        }
    }
}

.filter-menu {
    background-color: #fff8f2;
    padding: 30px 0px;
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 102%;
    // display: none;
    opacity: 1;
    transition: 0.3s all ease-in;
    box-shadow: 0 8px 6px -6px #939393;

    .toggle-btn {
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px solid #8c5d2f;
        display: flex;
        justify-content: space-evenly;

        .MuiToggleButtonGroup-root {
            .MuiToggleButton-root {
                padding: 5px 25px !important;
                line-height: 1.1;
            }

            .border-left {
                border-right: 1px solid #8c5d2f !important;
            }

            .MuiToggleButton-root.Mui-selected {
                background-color: transparent;
                color: #8c5d2f;
            }
        }
    }

    .price-range {
        margin-top: 30px;

        h5 {
            font-weight: bold;
            color: #8c5d2f;
            text-align: initial;
            font-size: 18px;
            font-family: 'FuturaPtBook' !important;
        }

        .MuiSlider-colorPrimary {
            margin: 0px 5px 20px 5px;
            color: #8c5d2f !important;
            height: 2px;

            .MuiSlider-rail {
                opacity: unset;
            }

            .MuiSlider-valueLabel {
                color: #8c5d2f;
                top: 55px;
                background-color: #fff8f2;
                padding: 0px;
            }

            .MuiSlider-thumb {
                width: 13px;
                height: 13px;
            }
        }
    }

    .color-designer {
        // margin: 30px 0px;

        h5 {
            font-weight: bold;
            color: #8c5d2f;
            text-align: initial;
            font-size: 18px;
            font-family: 'FuturaPtBook' !important;
            margin-bottom: 0px;
        }

        .toggle-btn {
            text-align: initial;
            padding-bottom: 30px;
            border-bottom: none;

            .MuiToggleButtonGroup-root {
                .MuiToggleButton-root {
                    padding: 2px 7px !important;
                    line-height: 1.1;
                }

                .border-left {
                    border-right: 1px solid #8c5d2f !important;
                }

                .MuiToggleButton-root.Mui-selected {
                    background-color: transparent;
                    color: #8c5d2f;
                }
            }
        }

        .check-icons {
            display: inline-block;
            margin-top: 10px;

            .check {
                padding-right: 25px;
                min-width: 120px;
                display: inline-flex !important;
                margin-bottom: 15px;
                width: 200px;

                .MuiCheckbox-root {
                    padding: 0px;
                    padding-right: 5px;

                    svg {
                        margin-right: 0px;
                        font-size: 13px;
                        color: #8c5d2f;
                    }
                }

                p {
                    margin-bottom: 0;
                    line-height: 1;
                    font-weight: 100 !important;
                }
            }
        }
    }

    .list-detail {
        h5 {
            font-weight: bold;
            color: #8c5d2f;
            text-align: initial;
            font-size: 18px;
            font-family: 'FuturaPtBook' !important;
            margin-bottom: 20px;
        }

        .check-icons {
            .check {
                padding-right: 15px;
                margin-bottom: 15px;

                .MuiCheckbox-root {
                    padding: 0px;
                    padding-right: 5px;

                    svg {
                        margin-right: 0px;
                        font-size: 13px;
                        color: #8c5d2f;
                    }
                }

                p {
                    margin-bottom: 0;
                    line-height: 1;
                    font-weight: 100 !important;
                }
            }
        }
    }

    .apply-btn {
        margin-top: 20px;

        button {
            background-color: #8c5d2f;
            border-radius: 0px;
            font-weight: 200;
            line-height: 30px;
            color: white;
            border: none;
            padding: 5px 30px;
            font-size: 16px;
            font-family: 'FuturaPtBook' !important;
        }
    }
}

.filter-parent {
    display: initial;

    .dropdown-menu.show {
        display: block;
        position: relative !important;
        transform: translate(0px, 28px) !important;
        min-width: 100%;
        max-width: 100%;
        padding: 0;
        border: none;
    }

    &:hover .filter-menu {
        display: block;
    }
}

@media screen and (max-width: 992px) {
    .filter-menu {
        .heading {
            padding: 10px 0px;
        }

        .contents {
            margin-top: 0px;
        }

        .storage-contents {
            margin-top: 0px;
        }
    }
}

.product-by-designer {
    width: 100%;
    background-size: cover;
    height: 500px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    h2 {
        font-size: 40px;
        text-align: center;
        color: #fff !important;
        text-transform: uppercase;
        font-family: 'FuturaPtBook' !important;
        letter-spacing: 1px;
    }

    p {
        color: #fff !important;
        font-family: Mulish-Light !important;
        text-align: center;
        font-size: 16px;
    }

    .Follow-button {
        Button {
            background-color: #8c5d2f !important;
            color: #fff !important;
            padding: 3px 10px;
            display: flex;
            width: 50%;
            margin: auto;
            font-size: 17px;
            border-radius: 0px !important;
        }

        .icon {
            color: #fff !important;
            font-size: 19px;
        }
    }
}

.MuiChip-icon {
    background-color: transparent;
}

.css-382pzk-MuiButtonBase-root-MuiChip-root.MuiChip-clickable:hover {
    background-color: transparent;
}

.addBlog {
    .input {
        background: transparent;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 5px;

        div {
            background: transparent;
            padding: 11px;
        }

        fieldset {
            border: none;
            background: transparent;
        }

        input {
            background: transparent;
            padding: 0px;
        }

        input::placeholder {
            font-family: Mulish-Light;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            color: #999999;
        }
    }

    textarea {
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 5px;
        background: transparent;
        padding: 10px;
        outline: none;
    }

    textarea::placeholder {
        font-family: Mulish-Light;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #999999;
    }

    .select {
        background: transparent;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 5px;
        outline: 0px;

        div {
            background: transparent;
            padding: 11px;
            color: grey;
            font-weight: 400;
        }

        fieldset {
            border: none;
            box-sizing: border-box;
            border-radius: 5px;
        }
    }

    .label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        color: #292929;
    }

    .time {
        .MuiFormControl-root {
            width: 100%;

            .MuiOutlinedInput-root {
                width: 100% !important;
                padding: 11px 0.75rem;
                border: 1px solid #999999;
                color: #808080;
                border-radius: 4px;
                background-color: #f8f8f8;

                .MuiOutlinedInput-input {
                    border: none;
                    padding: 0px;
                }
            }
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                padding: 11px 0.75rem;
                border: 1px solid #999999;
                color: #808080;
                border-radius: 4px;
                background-color: #f8f8f8;
                width: 100% !important;

                em {
                    font-style: normal;
                    font-weight: 100;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }

    .upload-btn {
        position: relative;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        width: 150px;
        height: 150px;
        font-family: 'FuturaPtBook' !important;

        label {
            color: #a3a3a3;
            font-size: 18px;
        }

        .plus-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-17px, -17px);
        }

        .plus-icon svg {
            font-size: 30px;
        }

        #file-input,
        #file-input2,
        #video-input {
            display: none;
        }
        #additional-input {
            display: none;
        }
    }

    .ckEditor {
        .rdw-dropdown-selectedtext {
            color: black;
        }
        .demo-editor {
            margin-top: 20px;
            overflow-x: hidden;
            overflow-y: auto;
            box-sizing: border-box;
            background: #ffff;
            border-radius: 12px;
            padding: 10px;
            min-height: 170px;
        }

        .ck.ck-icon {
            color: #28bca6;
        }

        .ck.ck-toolbar {
            background: transparent;
            padding: 0 var(--ck-spacing-small);
            border: none;
        }

        .ck.ck-editor__editable_inline {
            overflow: auto;
            padding: 0 var(--ck-spacing-standard);
            border: none;
            border-radius: 12px !important;
            min-height: 200px;
        }
    }
}

.addProduct {
    .delIcon {
        color: red;
    }

    .saveBtn {
        background: #c3724e;
        border-radius: 10px;
        font-family: Mulish-Light;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        text-transform: capitalize;
        color: #ffffff;
        border: none;
        width: 200px;
    }

    .draftBtn {
        background: #949494;
    }

    .submitBtn {
        background: #4e7eb1;
    }

    table {
        img {
            width: 70px;
            height: 85px;
        }

        thead {
            tr {
                background: #efefefa6;
            }

            th {
                border-bottom-width: 2px;
            }
        }

        tr:nth-child(even) {
            background: #efefefa6;
        }
    }

    .variantBtn {
        border-radius: 10px;
        width: 200px;
    }

    .radioMain {
        svg {
            fill: #c3724e;
        }
    }

    .label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0px;
        color: #292929;
    }

    .manufacturer {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        color: #999999;
        margin-bottom: 10px;
    }

    input {
        background: transparent;
    }

    select {
        background-color: transparent;
    }

    textarea {
        background-color: transparent;
    }

    .multiselect {
        div > div {
            margin-top: 0px;
        }

        .MuiSelect-select {
            padding: 7px 0.75rem !important;
        }
    }
}

.addProductModal {
    .multiselect {
        div > div {
            margin-top: 0px;
        }
    }

    .label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        margin: 10px 0px;
        color: #292929;
    }

    .MuiSelect-select {
        border: 1px solid #ced4da;
        color: #808080;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 7px 0.75rem !important;
    }

    input {
        padding: 4px;
    }

    .label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
        color: #292929;
    }

    .upload-btn {
        position: relative;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        width: 150px;
        height: 150px;
        font-family: 'FuturaPtBook' !important;

        label {
            color: #a3a3a3;
            font-size: 18px;
        }

        .plus-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-17px, -17px);
        }

        .plus-icon svg {
            font-size: 30px;
        }

        #file-input,
        #file-input2,
        #video-input {
            display: none;
        }
        #additional-input {
            display: none;
        }
    }
}

.variationMain {
    overflow: hidden;
    overflow-x: scroll;

    .variationCard {
        background-color: rgb(255 255 255);
        border-radius: 10px;
        border-radius: 5px;
        padding: 2%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: black;
        margin-top: 1px;
        box-shadow: 3px 3px 6px 2px rgb(0 0 0 / 15%);
        margin-top: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        align-items: center;

        @media screen and (max-width: 767px) {
            width: 800px;
        }

        img {
            height: 50px;
            width: 50px;
        }
    }
}

.MuiCollapse-root .MuiListItemButton-root:hover {
    background-color: #c3724e !important;
    color: white !important;
}

.MuiCollapse-root .MuiListItemButton-root.Mui-selected:hover {
    background-color: #c3724e !important;
    color: white !important;
}

.MuiCollapse-root .MuiListItemButton-root.Mui-selected:hover .MuiListItemIcon-root {
    color: white !important;
}

.MuiCollapse-root .MuiListItemButton-root.Mui-selected {
    color: white !important;
    background-color: #c3724e !important;
}

.MuiCollapse-root .MuiListItemButton-root.Mui-selected .MuiListItemIcon-root {
    color: white !important;
}

.upload-btn {
    position: relative;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 8px;
    width: 150px;
    height: 150px;
    margin-top: 10px;

    label {
        color: #a3a3a3;
        font-size: 18px;
        width: 100%;
        display: block;
        height: 150px;
        cursor: pointer;
    }

    .plus-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-17px, -17px);
    }

    .plus-icon svg {
        font-size: 30px;
    }

    #file-input,
    #file-input2,
    #video-input {
        display: none;
    }
    #additional-input {
        display: none;
    }
}

.upload-btn:hover {
    background-color: #eaeaea;
}

.form-check-input:checked {
    background-color: #c3724e;
    border-color: #f8f8f8;
    height: 16px;
    width: 16px;
    margin-top: 0px;
}

.form-check-input {
    margin-top: 0px;
}

.label {
    font-family: Mulish-Bold !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    margin: 14px 0px !important;
    color: #292929 !important;
    line-height: 20px !important;
}

.register-dialog {
    .logo {
        padding: 10px;
    }

    .logo img {
        width: 120px;
    }

    .centerimg img {
        height: 120px;
    }

    .centerimg p {
        text-align: center !important;
        font-family: Redressed-Regular !important;
        color: #000 !important;
        padding: 30px 0px;
    }
}

.customModalMain {
    .MuiDialog-paper {
        border-radius: 0px !important;
        background-color: #e5d1c1 !important;
    }

    .customModal {
        padding: 11.5%;

        .closeIcon {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        p {
            font-family: 'FuturaPTMedium' !important;
            font-size: 18px;
            margin-bottom: 50px;
            color: #a07b56;
            margin-bottom: 50px !important;
            line-height: 25px;

            @media screen and (max-width: 767px) {
                margin-bottom: 30px !important;
            }
        }

        input {
            padding: 10px 0px;
            background: transparent;
            border: none;
            border-radius: 0px;
            width: 100%;
            margin-bottom: 20px;
            font-family: 'FuturaPTMedium' !important;
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f;

            &::placeholder {
                color: #8c5d2f;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        textarea {
            padding: 10px 10px;
            background: #a07b56;
            border: 1px solid #a07b56;
            border-radius: 0px;
            width: 100%;
            margin-bottom: 20px;
            color: white;
            font-family: 'FuturaPTBook' !important;

            &::placeholder {
                color: white;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .yes {
            width: 100%;
            background-color: #8c5d2f;
            border: 1px solid #8c5d2f !important;
            color: white;
            font-family: 'FuturaPtBook' !important;
            border-radius: 0px !important;
            box-shadow: none !important;
        }

        .no {
            width: 100%;
            background-color: #8c5d2f;
            border: 1px solid #8c5d2f !important;
            color: #8c5d2f;
            font-family: 'FuturaPtBook' !important;
            border-radius: 0px !important;
            box-shadow: none !important;
        }

        .product-img {
            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                margin-right: 7px;
            }

            p {
                color: #8c5d2f;
                margin-bottom: 0px;
                font-size: 14px;
                font-family: 'FuturaPtBook' !important;
            }

            h5 {
                color: #8c5d2f;
                margin-bottom: 0px;
                font-size: 16px;
                font-family: 'FuturaPtBook' !important;
                text-align: left;
            }
        }

        .rating {
            margin: 20px 0px;

            svg {
                color: #8c5d2f;
                margin: 0px 5px;
            }
        }
    }
    .MuiOutlinedInput-root {
        background: transparent;
        border: none;
        border-radius: 0px;
        width: 100%;
        margin-bottom: 20px;
        font-family: 'FuturaPTMedium' !important;
        color: #8c5d2f;
        border-bottom: 1px solid #8c5d2f;
        padding: 10px;
    }
    .MuiInputBase-root .MuiSelect-select {
        background: transparent;
        border-radius: 0px;
        color: #8c5d2f;
        font-size: 15px;
        text-align: left;
        padding: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .MuiSelect-icon {
        height: 40px !important;
        right: 0px;
    }
    fieldset {
        border: 0;
    }
}

.model {
    border-radius: 0px;

    .logo {
        padding: 10px;

        img {
            width: 120px;
        }
    }

    .centerimg {
        img {
            width: 150px;
            margin-top: 50px;
        }

        p {
            text-align: center !important;
            font-family: Redressed-Regular !important;
            color: #000 !important;
            padding: 30px 0px;
            font-size: 30px;
            line-height: 1.2;
        }
    }
}

.product-modal {
    border-radius: 0px;

    .logo {
        padding: 10px;

        img {
            width: 120px;
        }
    }

    .centerimg {
        width: 80%;
        margin: 0 auto;

        img {
            width: 110px;
        }

        p {
            text-align: center !important;
            font-family: Mulish-Bold !important;
            color: #000 !important;
            padding: 30px 0px;
            font-size: 20px;
            line-height: 1.2;
        }

        .brownBtn {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            font-size: 14px;
            border: none;
            border-radius: 3px;
            color: white;
        }
    }
}

.futuraMedium {
    font-family: 'FuturaPtMedium' !important;
}

.pending-payment {
    background: #fbfbfb;

    h4 {
        color: #b16846;
        margin-bottom: 0px;
    }
}

.timeline {
    .MuiTimeline-root {
        display: flex !important;
        align-items: stretch !important;
    }

    img {
        width: 40px;
        height: 40px;
        padding: 5px;
        filter: brightness(3);
    }

    .timeline-img-back {
        background-color: #b16846 !important;
    }
}

.paymentTitle {
    color: #8c5d2f;
    font-size: 18px;
    font-family: 'FuturaPtMedium' !important;
}

.ElementsApp .InputElement.is-invalid {
    color: #8d5e31 !important;
}

.customModalMain button {
    box-shadow: none;
    border-radius: 0px !important;
    text-transform: capitalize;
    background-color: none !important;
    border: none;
    background-color: #8c5d2f;
    padding: 10px;
    box-shadow: none !important;
    color: white !important;
    font-family: 'FuturaPtMedium' !important;
}

.StripeElement {
    .ElementsApp {
        .InputElement {
            padding: 10px 0px;
            background: transparent;
            border: none;
            border-radius: 0px;
            width: 100%;
            margin-bottom: 20px;
            color: #8c5d2f !important;
            border-bottom: 1px solid #8c5d2f;

            &::placeholder {
                color: #8c5d2f;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.StripeElement {
    padding: 30px 0px !important;
}

.CardField-child {
    transform: translateX(0px) !important;
}

.stripeModalMain .MuiDialog-paper {
    width: 600px !important;
}

.pending-payment-stepper {
    padding: 20px;

    h1 {
        color: #8c5d2f;
        padding: 0px 20px;
        margin: 0px;
        font-size: 20px;
    }

    .payment-stepper {
        width: 80%;
        border-left: 2px solid #cccccc;
    }
}

.orderDetails {
    .input {
        background: transparent;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 5px;

        div {
            background: transparent;
            padding: 11px;
        }

        fieldset {
            border: none;
            background: transparent;
        }

        input {
            background: transparent;
            padding: 0px;
        }

        input::placeholder {
            font-family: Mulish-Light;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            color: #999999;
        }
    }

    .order-status {
        padding-bottom: 17px;
        border-bottom: 1px solid #e2e2e2;

        .select {
            background: transparent;
            border: 1px solid #999999;
            box-sizing: border-box;
            border-radius: 5px;
        }

        em {
            font-style: normal;
            font-family: 'FuturaPtBook' !important;
            outline: 0px;

            div {
                background: transparent;
                padding: 11px;
                color: grey;
                font-weight: 400;
            }

            fieldset {
                border: none;
                box-sizing: border-box;
                border-radius: 5px;
            }

            em {
                font-style: normal;
                font-family: Mulish-SemiBold !important;
            }
        }
    }

    .label {
        font-family: Mulish-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        color: #292929;
    }

    .upload-btn {
        position: relative;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        width: 150px;
        height: 150px;
        font-family: 'FuturaPtBook' !important;

        label {
            color: #a3a3a3;
            font-size: 18px;
        }

        .plus-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-17px, -17px);
        }

        .plus-icon svg {
            font-size: 30px;
        }

        #file-input,
        #file-input2,
        #video-input {
            display: none;
        }
        #additional-input {
            display: none;
        }
    }

    .table-responsive {
        p {
            color: #999999;
            font-size: 17px;
            margin-top: 15px;
        }

        .table {
            border-bottom: 1px solid #999999;

            thead {
                border: none !important;
                background: #ebebeb !important;

                tr {
                    th {
                        padding: 16px 20px !important;
                        border: none !important;
                    }
                }
            }

            tbody {
                border: none !important;

                tr {
                    border-bottom: 1px solid #99999963;

                    td {
                        border: none !important;
                        padding: 16px 20px !important;

                        img {
                            width: 55px;
                            height: 55px;
                            object-fit: cover;
                            border-radius: 10px;
                        }

                        span {
                            margin: 0px;
                        }

                        input {
                            border: 1px solid #c4c4c4;
                            padding: 4px 4px;
                            border-radius: 8px;
                            outline: none;
                        }
                    }
                }
            }
        }
    }

    .total-price {
        border-bottom: 1px solid #d3d3d3;

        p {
            color: #999999;
            font-size: 17px;
            margin-top: 15px;
        }

        h5 {
            font-size: 16px;
            margin-bottom: 15px;
            margin-left: 20px;
        }
    }

    .order-id {
        color: #c3724e;
        font-size: 16px;
        margin-left: 20px;
    }

    .payment_method {
        h5 {
            color: #999999;
            font-size: 17px;
            margin-top: 20px;
            margin-bottom: 22px;
            font-family: Mulish-Light !important;
            font-weight: bold;
        }

        .manufecturer {
            .cost-name {
                padding: 15px 20px;
                border-bottom: 1px solid #999999;
                padding-right: 20px;
            }

            .margin {
                margin: 0px 40px;
            }

            strong {
                color: black !important;
            }
        }

        .radio-btns {
            input[type='radio'] {
                opacity: 0;

                + label {
                    cursor: pointer;
                    position: relative;
                    margin-left: 20px;
                    margin-right: 15px;
                    vertical-align: text-top;

                    img {
                        max-width: 25px;
                        max-height: 25px;
                        min-width: 40px;
                        min-height: 23px;
                    }

                    &::before {
                        border: 1px solid gray;
                        border-radius: 50%;
                        content: '';
                        height: 18px;
                        left: -23px;
                        position: absolute;
                        top: 1px;
                        width: 18px;
                    }

                    &::after {
                        border-radius: 50%;
                        content: '';
                        height: 12px;
                        left: -20px;
                        position: absolute;
                        top: 4px;
                        width: 12px;
                    }
                }

                &:focus {
                    + label::before {
                        box-shadow: 0 0 0 2px rgba(21, 156, 228, 0.7);
                    }
                }

                &:checked {
                    + label::after {
                        background: #8c5d2f;
                    }
                }
            }
        }
    }

    .payment-input {
        border: 1px solid #c4c4c4;
        border-radius: 8px;
        background: transparent;
        padding: 11px;
        color: grey;
        font-weight: 400;
        outline: none;
    }
}

.report {
    .heading {
        margin-bottom: 20px;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
            line-height: 1.7;
        }
    }

    .card-expensive-item {
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .card-head {
            padding: 10px 16px;
            color: #fff;
            background-color: #b16846;
            font-family: 'Mulish-SemiBold';
            border-radius: 8px 8px 0px 0px;
        }

        .card-body {
            padding: 15px;
            border: 2px solid #999999;
            border-top: none;
            border-radius: 0px 0px 8px 8px;

            img {
                height: 90px;
                width: 100px;
                object-fit: cover;
                border-radius: 6px;
            }

            .body-data {
                padding-left: 20px;
            }
        }
    }

    .detail-btn {
        text-align: end;

        @media screen and (max-width: 575px) {
            text-align: left;
        }

        .viewbtn {
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 7px !important;
        }
    }

    .addthread-btn {
        .addthreadbtn {
            background-color: #b16846 !important;
            border-color: #5cc5e4 !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 5px !important;
        }
    }

    .lecture-rescheduling {
        padding: 15px;
        background-color: #fff;
        border-radius: 14px;

        h5 {
            font-size: 16px;

            @media screen and (max-width: 321px) {
                color: #000;
            }
        }

        .designer-btn {
            text-align: end;

            @media screen and (max-width: 575px) {
                text-align: left;
            }

            .designerbtn {
                background-color: #5cc5e4 !important;
                border-color: #5cc5e4 !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }

        .seeMore-btn {
            text-align: left;

            @media screen and (max-width: 575px) {
                display: flex;
                justify-content: center;
            }

            .seeMorerbtn {
                background-color: transparent !important;
                border-color: #5cc5e4 !important;
                color: Grey;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }

        .date p {
            color: #999999;
            margin-bottom: 10px;
        }

        .response {
            position: relative;

            .send-btn {
                text-align: left;

                @media screen and (max-width: 575px) {
                    text-align: left;
                }

                .sendbtn {
                    background-color: transparent !important;
                    border-color: #5cc5e4 !important;
                    color: Grey;
                    border-radius: 0px !important;
                    box-shadow: none !important;
                    font-family: Mulish-SemiBold !important;
                    border-radius: 5px !important;
                    position: absolute;
                    right: 20px;
                    color: #c3724e;
                    top: 2px;
                    transform: translate(10px, 0px);
                }
            }

            .text {
                position: absolute;
                right: 20px;
                color: #c3724e;
                top: 2px;
                transform: translate(10px, 0px);
            }

            input {
                width: 100% !important;
                padding: 10px !important;
                border: none;
                background-color: #fbfbfb !important;
            }
        }

        .responses h2 {
            color: gray;
            font-size: 16px;
            margin-top: 40px;
            margin-bottom: 20px;

            @media screen and (max-width: 576px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .barryimg img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    .export-btn {
        text-align: end;

        @media screen and (max-width: 575px) {
            text-align: left;
            margin-bottom: 15px;
        }

        .exportbtn {
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            border-radius: 7px !important;
        }
    }

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            input {
                padding: 5px;
                border: 1px solid #999999;
                border-radius: 6px;
                width: 100%;
            }

            :focus-visible {
                padding: 5px;
                border: 2px solid #999999;
                border-radius: 6px;
            }

            .time-container {
                display: none;
            }
        }
    }

    .date-selector {
        label {
            font-weight: 500;
            margin-bottom: 7px;
        }
    }

    h1 {
        font-family: Mulish-SemiBold !important;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 35px;
        color: #272727;
        margin-bottom: 0px;
    }

    .filter-design {
        margin-bottom: 5px;

        label {
            color: #000;
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                width: 100% !important;
                padding: 4px;
                border: 2px solid #999999;
                color: Grey;
                border-radius: 4px;
                background-color: #fcfcfb;

                em {
                    font-style: normal;
                    font-family: Mulish-SemiBold !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
                .MuiInputBase-input {
                    width: 100% !important;
                    font-size: 14px;
                    color: gray;
                    padding: 7px 12px;
                }

                .MuiInputBase-input::placeholder {
                    color: #808080 !important;
                    font-weight: 400;
                    opacity: 1;
                }
            }
        }

        .input-field {
            margin-bottom: 10px;
        }
    }

    .filter-fields {
        @media screen and (max-width: 767px) {
            margin-bottom: 15px;
            margin-top: 30px;
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                width: 100% !important;
                padding: 6px 4px;
                padding-right: 50px;
                border: 1px solid #999999;
                color: black;
                border-radius: 4px;
                background-color: #fcfcfb;

                em {
                    font-style: normal;
                    font-family: Mulish-SemiBold !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
                .MuiInputBase-input {
                    width: 100% !important;
                    font-size: 14px;
                    color: gray;
                    padding: 7px 12px;
                }

                .MuiInputBase-input::placeholder {
                    color: #808080 !important;
                    font-weight: 400;
                    opacity: 1;
                }
            }
        }

        .input-field {
            margin-bottom: 10px;
        }
    }

    .dashboardCard {
        padding: 20px;
        background: #fff7b3;
        border-radius: 7px;

        .MuiTypography-p {
            font-family: Mulish-SemiBold !important;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #272727;
            margin-bottom: 19px;

            @media screen and (max-width: 992px) {
                font-family: Mulish-SemiBold !important;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                color: #272727;
                margin-bottom: 19px;
            }
        }

        .MuiTypography-h3 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 33px;
            color: #454545;

            @media screen and (max-width: 992px) {
                font-family: Mulish-Bold !important;
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                color: #454545;
            }
        }

        .textContainer {
            padding: 0px !important;
        }

        img {
            width: 59px;
            height: 65px;

            @media screen and (max-width: 992px) {
                width: 50px;
                height: 55px;
            }
        }
    }

    .dashboardCard.income {
        background: #ffe2e2;
    }

    .dashboardCard.orders {
        background: #d1ffb5;
    }

    .dashboardCard.chats {
        background: #e3e2ff;

        img {
            width: 64px;
            height: 57px;
        }
    }

    .dashboardCard.process {
        background: #bbffeb;

        img {
            width: 85px;
            height: 57px;
        }
    }

    .dashboardCard.delivery {
        background: #c2f0ff;
    }

    #chart {
        background: #ffffff;
        border-radius: 14px;
        padding: 20px;
        overflow: hidden !important;

        h4 {
            font-family: Mulish-Bold !important;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            color: #272727;
            text-align: center;
        }

        p {
            font-family: Mulish-Light !important;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            color: #272727;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .barChart {
        .apexcharts-canvas {
            margin: 0 auto !important;
        }
    }

    .view-button {
        .MuiButtonBase-root {
            border-radius: 0px;
        }
    }
}

.tabs-btn {
    margin-bottom: 10px;
    text-align: end;

    .btn-primary {
        color: #fff;
        background-color: #999999 !important;
        border-color: #999999 !important;
        font-family: Mulish-Light !important;
    }

    .btn-primary:focus {
        box-shadow: none !important;
    }

    .btn-group {
        flex-wrap: wrap !important;
    }

    .btn-check {
        &:active + .btn-primary {
            color: #fff;
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            outline: none;
        }

        &:checked + .btn-primary {
            color: #fff;
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            outline: none;
        }
    }

    .btn-primary.active {
        color: #fff;
        background-color: #c3724e !important;
        border-color: #c3724e !important;
        outline: none;
    }

    .btn-primary {
        &:active {
            color: #fff;
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            outline: none;
        }
    }

    .show {
        > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            outline: none;
        }
    }
}

.community {
    .heading {
        margin-bottom: 10px;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
            line-height: 1.7;
        }
    }

    .tabs-btn {
        margin-bottom: 10px;
        text-align: end;

        .btn-secondary {
            color: #fff;
            background-color: #999999 !important;
            border-color: #999999 !important;
            font-family: Mulish-Light !important;
            width: 110px;
        }

        .btn-secondary:focus {
            box-shadow: none !important;
        }

        .btn-group {
            flex-wrap: wrap !important;
        }

        .btn-check {
            &:active + .btn-secondary {
                color: #fff;
                background-color: #c3724e !important;
                border-color: #c3724e !important;
                outline: none;
            }

            &:checked + .btn-secondary {
                color: #fff;
                background-color: #c3724e !important;
                border-color: #c3724e !important;
                outline: none;
            }
        }

        .btn-secondary.active {
            color: #fff;
            background-color: #c3724e !important;
            border-color: #c3724e !important;
            outline: none;
        }

        .btn-secondary {
            &:active {
                color: #fff;
                background-color: #c3724e !important;
                border-color: #c3724e !important;
                outline: none;
            }
        }

        .show {
            > .btn-secondary.dropdown-toggle {
                color: #fff;
                background-color: #c3724e !important;
                border-color: #c3724e !important;
                outline: none;
            }
        }
    }

    .addthread-btn {
        .addthreadbtn {
            background-color: #b16846 !important;
            border-color: #5cc5e4 !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 6px !important;
        }

        svg {
            margin-right: 8px;
            font-size: 19px;
        }
    }

    .lecture-rescheduling {
        padding: 15px;
        background-color: #fff;
        border-radius: 14px;

        h5 {
            font-size: 16px;

            @media screen and (max-width: 321px) {
                color: #000;
            }
        }

        .designer-btn {
            text-align: end;

            @media screen and (max-width: 575px) {
                text-align: left;
            }

            .designerbtn {
                background-color: #5cc5e4 !important;
                border-color: #5cc5e4 !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }

        .seeMore-btn {
            text-align: left;

            @media screen and (max-width: 575px) {
                display: flex;
                justify-content: center;
            }

            .seeMorerbtn {
                background-color: transparent !important;
                border-color: #5cc5e4 !important;
                color: Grey;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }

        .date p {
            color: gray;
        }

        .response {
            position: relative;

            .send-btn {
                text-align: left;

                @media screen and (max-width: 575px) {
                    text-align: left;
                }

                .sendbtn {
                    background-color: transparent !important;
                    border-color: #5cc5e4 !important;
                    color: Grey;
                    border-radius: 0px !important;
                    box-shadow: none !important;
                    font-family: Mulish-SemiBold !important;
                    border-radius: 5px !important;
                    position: absolute;
                    right: 20px;
                    color: #c3724e;
                    top: 2px;
                    transform: translate(10px, 0px);
                }
            }

            .text {
                position: absolute;
                right: 20px;
                color: #c3724e;
                top: 2px;
                transform: translate(10px, 0px);
            }

            input {
                width: 100% !important;
                padding: 10px !important;
                border: none;
                background-color: #fbfbfb !important;
                outline: none;
            }
        }

        .responses h2 {
            color: gray;
            font-size: 16px;
            margin-top: 40px;
            margin-bottom: 20px;

            @media screen and (max-width: 576px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .barryimg img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 8px;
        }
    }
}

.addthread {
    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .form-control {
        background: transparent;
    }

    .filter-fields {
        @media screen and (max-width: 575px) {
            margin-bottom: 15px;
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                width: 100% !important;
                padding: 8px;
                padding-right: 50px;
                border: 1px solid #888888;
                color: Grey;
                border-radius: 4px;
                background-color: transparent;

                em {
                    font-style: normal;
                    font-family: Mulish-SemiBold !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
                .MuiInputBase-input {
                    width: 100% !important;
                    font-size: 14px;
                    color: gray;
                    padding: 7px 12px;
                }

                .MuiInputBase-input::placeholder {
                    color: #808080 !important;
                    font-weight: 400;
                    opacity: 1;
                }
            }
        }

        .input-field {
            margin-bottom: 10px;
        }
    }

    .addthread-btn {
        margin-top: 20px;

        .addthreadbtn {
            background-color: #b16846 !important;
            border-color: #5cc5e4 !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 5px !important;
        }
    }

    .create-btn {
        margin-top: 20px;

        .createbtn {
            background-color: #999999 !important;
            border-color: #5cc5e4 !important;
            border-radius: 0px !important;
            width: 100%;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 5px !important;
        }
    }
}

.addphoto-btn {
    margin-bottom: 10px;

    .addphotobtn {
        padding: 6px 30px;
        background: #c3724e;
        text-transform: initial;
        box-shadow: none;
        border: none;
        font-family: Mulish-SemiBold !important;
    }
}

.gallery {
    .heading {
        margin-bottom: 10px;
    }

    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .upload-btn {
        position: relative;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        width: 150px;
        height: 150px;
        font-family: 'FuturaPtBook' !important;

        label {
            color: #a3a3a3;
            font-size: 18px;
        }

        .plus-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-17px, -17px);
        }

        .plus-icon svg {
            font-size: 30px;
        }

        #file-input,
        #file-input2,
        #video-input {
            display: none;
        }
        #additional-input {
            display: none;
        }
    }

    .addphoto-btn {
        margin-bottom: 10px;

        .addphotobtn {
            background-color: #b16846 !important;
            border-color: #5cc5e4 !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 6px !important;
        }
    }

    .barryimg img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 8px;
    }
}

.addphoto {
    .heading {
        margin-bottom: 10px;
    }

    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .upload-btn {
        position: relative;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        width: 150px;
        height: 150px;
        font-family: FuturaPTBook !important;

        label {
            color: #a3a3a3;
            font-size: 18px;
        }

        .plus-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-17px, -17px);
        }

        .plus-icon svg {
            font-size: 30px;
        }

        #file-input,
        #file-input2,
        #video-input {
            display: none;
        }
        #additional-input {
            display: none;
        }
    }
}

.blogpost {
    .heading {
        margin-bottom: 10px;
    }

    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .getting-ahead {
        border-bottom: 2px solid #999999;
        margin-bottom: 10px;

        span {
            color: #3cc2ae;
        }
    }

    .blog {
        padding: 15px;
        background-color: #fff;
        box-shadow: 0px 1px 8px 0px #000000 8%;
        border-radius: 12px;
    }

    .note {
        padding: 10px;
        background-color: #f7f7f7;

        p {
            margin: 0px;
        }
    }

    .approval {
        padding: 30px;

        p {
            text-align: center;
        }

        .rejected-btn {
            .rejectedbtn {
                background-color: #e35757 !important;
                border-color: #5cc5e4 !important;
                display: flex;
                margin: auto;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 4px !important;
            }

            .approvebtn {
                background-color: #57e376 !important;
                border-color: #5cc5e4 !important;
                display: flex;
                margin: auto;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 4px !important;
            }
        }
    }
}

.HomepageBannerSettings {
    .heading {
        margin-bottom: 10px;
    }

    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .upload-btn {
        position: relative;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 8px;
        width: 150px;
        height: 150px;
        font-family: FuturaPTBook !important;

        label {
            color: #a3a3a3;
            font-size: 18px;
        }

        .plus-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-17px, -17px);
        }

        .plus-icon svg {
            font-size: 30px;
        }

        #file-input,
        #file-input2,
        #video-input {
            display: none;
        }
        #additional-input {
            display: none;
        }
    }
}

.ruleaddedit {
    .MuiCheckbox-root.Mui-checked svg {
        color: #f8f8f8;
        outline: 0;
        border-radius: 4px;
        background: #c3724e;
    }

    textarea {
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 5px;
        background: transparent;
        padding: 10px;
        outline: none;
    }

    input,
    select {
        background-color: transparent !important;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .toggle {
        .Mui-selected {
            color: #ffffff;
            background-color: #c3724e !important;
        }

        .MuiToggleButton-root {
            padding: 6px 25px;
            background: #999999;
            color: white;
        }
    }

    .date-selector {
        label {
            font-family: Mulish-Bold !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 18px !important;
            margin: 10px 0px !important;
            color: #292929 !important;
            line-height: 20px !important;
        }
    }

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            input {
                padding: 5px;
                border: 1px solid #999999;
                border-radius: 6px;
                width: 100%;
            }
        }
    }

    .heading {
        margin-bottom: 10px;
    }

    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .filter-fields {
        @media screen and (max-width: 575px) {
            margin-bottom: 15px;
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                width: 100% !important;
                padding: 8px;
                padding-right: 50px;
                border: 1px solid #999999;
                color: Grey;
                border-radius: 4px;
                background-color: transparent;

                em {
                    font-style: normal;
                    font-family: Mulish-SemiBold !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
                .MuiInputBase-input {
                    width: 100% !important;
                    font-size: 14px;
                    color: gray;
                    padding: 7px 12px;
                }

                .MuiInputBase-input::placeholder {
                    color: #808080 !important;
                    font-weight: 400;
                    opacity: 1;
                }
            }
        }

        .input-field {
            margin-bottom: 10px;
        }
    }

    .condition label {
        color: #c3724e !important;
    }

    .socialaccount {
        border-top: 2px solid #999999;
        margin-top: 10px;
        color: #999999;

        p {
            padding-top: 10px;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .socialaccount {
        border-top: 1px solid #999999;
        margin-top: 10px;
        color: #999999;

        p {
            padding-top: 10px;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .seperation {
        color: #999999;

        p {
            padding-top: 10px;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .conditions {
        border-top: 1px solid #999999;
        margin-top: 10px;
        color: #999999;

        p {
            padding-top: 10px;
        }

        span {
            color: #c3724e;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .generated-coupon {
        margin-top: 10px;
        border-bottom: 1px solid #999999;

        p {
            margin-top: 10px;
            color: #999999;
        }
    }
}

.roleaddedit {
    textarea {
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 5px;
        background: transparent;
        padding: 10px;
        outline: none;
    }

    input,
    select {
        background-color: transparent !important;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .heading {
        margin-bottom: 10px;
    }

    .heading h2 {
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 1.7;
    }

    .MuiCheckbox-root.Mui-checked svg {
        color: #f8f8f8;
        outline: 0;
        border-radius: 4px;
        background: #c3724e;
    }

    .save-btn {
        text-align: center;
        margin-top: 30px;

        .savebtn {
            background-color: #999999 !important;
            border-color: #999999 !important;
            border-radius: 0px !important;
            padding: 6px 50px;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 5px !important;
        }
    }

    .approved {
        h3 {
            margin: 0px;
            font-size: 20px;
        }

        .toggle {
            .Mui-selected {
                color: #ffffff;
                background-color: #c3724e !important;
            }

            .MuiToggleButton-root {
                padding: 6px 25px;
                background: #999999;
                color: white;
            }
        }
    }

    .filter-fields {
        @media screen and (max-width: 575px) {
            margin-bottom: 15px;
        }

        .MuiOutlinedInput-root {
            width: 100% !important;

            .MuiSelect-select {
                width: 100% !important;
                padding: 4px;
                padding-right: 50px;
                border: 2px solid #999999;
                color: Grey;
                border-radius: 4px;
                background-color: #fcfcfb;

                em {
                    font-style: normal;
                    font-family: Mulish-SemiBold !important;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
                .MuiInputBase-input {
                    width: 100% !important;
                    font-size: 14px;
                    color: gray;
                    padding: 7px 12px;
                }

                .MuiInputBase-input::placeholder {
                    color: #808080 !important;
                    font-weight: 400;
                    opacity: 1;
                }
            }
        }

        .input-field {
            margin-bottom: 10px;
        }
    }

    .condition label {
        color: #c3724e !important;
    }

    .socialaccount {
        border-top: 2px solid #999999;
        margin-top: 10px;
        color: #999999;

        p {
            padding-top: 10px;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .socialaccount {
        border-top: 2px solid #999999;
        margin-top: 10px;
        color: #999999;

        p {
            padding-top: 10px;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .seperation {
        color: #999999;

        p {
            padding-top: 10px;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .conditions {
        color: #999999;

        p {
            padding-top: 10px;
        }

        span {
            color: #c3724e;
        }

        .addsocial-btn {
            margin-top: 10px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
            }
        }
    }

    .generated-coupon {
        margin-top: 10px;
        border-bottom: 2px solid #999999;

        p {
            margin-top: 10px;
            color: #999999;
        }
    }
}

.contactussettings {
    .heading {
        margin-top: 10px;
    }

    .heading h2 {
        font-size: 21px;
    }

    .form-control {
        background: transparent;
    }

    .input-field {
        .label {
            margin-top: 20px !important;
        }
    }

    .save-btn {
        text-align: center;
        margin-top: 35px;

        .savebtn {
            background-color: #999999 !important;
            border-color: #999999 !important;
            border-radius: 0px !important;
            padding: 6px 50px;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 5px !important;
        }
    }

    .socialaccount {
        margin-top: 10px;
        border-top: 1px solid #9999;
        padding-top: 10px;

        p {
            color: #999999;
        }

        .social-account-info {
            margin-right: 10px;
            margin-bottom: 15px;

            p {
                color: #999999;
                margin-bottom: 0px;
                font-size: 15px;
            }

            a {
                color: #c3724e;
                text-decoration: underline;
                font-size: 15px;
                margin-bottom: 10px;
            }

            .removeicon {
                color: #c3724e;
                font-size: 18px;
            }

            img {
                border-radius: 7px;
                height: 150px;
                width: 150px;
                object-fit: cover;
                margin-bottom: 5px;
            }
        }

        .addsocial-btn {
            margin-bottom: 10px;
            margin-top: 20px;

            .addsocialbtn {
                background-color: #c3724e !important;
                border-color: #5cc5e4 !important;
                border-radius: 0px !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 6px !important;
            }
        }
    }
}

.pricerange {
    .heading {
        margin-top: 10px;
    }

    .heading h2 {
        font-size: 21px;
    }

    .form-control {
        background: transparent;
    }

    .input-field {
        .label {
            margin-top: 20px !important;
        }
    }

    .save-btn {
        text-align: center;
        margin-top: 35px;

        .savebtn {
            background-color: #999999 !important;
            border-color: #999999 !important;
            border-radius: 0px !important;
            padding: 6px 50px;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 5px !important;
        }
    }

    .addsocial-btn {
        margin-bottom: 10px;
        margin-top: 20px;

        .addsocialbtn {
            background-color: #c3724e !important;
            border-color: #5cc5e4 !important;
            border-radius: 0px !important;
            box-shadow: none !important;
            font-family: Mulish-SemiBold !important;
            border-radius: 6px !important;
        }
    }
}

.notifications {
    .heading {
        margin-top: 10px;
    }

    .heading h2 {
        font-size: 21px;
        margin-bottom: 25px;
    }

    .list {
        padding: 15px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        background-color: #fff;

        .heading {
            margin-top: 10px;

            h2 {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }

        .closeicon {
            color: #999999;
        }

        .closeicon:hover {
            cursor: pointer;
        }

        p {
            width: 90%;
            margin: 0px;
        }

        h5 {
            text-align: end;
            color: #999999;
            font-size: 12px;
        }
    }
}

.close-popup {
    svg {
        margin-right: 12px;
        margin-top: 12px;
    }

    .form-control {
        display: block;
        width: 85%;
        margin: auto;

        @media screen and(max-width:575px) {
            width: 100%;
        }
    }

    .block-user-popup {
        padding: 10px 30px;
        margin: 0px auto;
        width: 80%;
        padding-bottom: 20px;
        text-align: center;

        @media screen and(max-width:575px) {
            width: 100%;
        }

        h5 {
            font-size: 18px;
            margin-bottom: 15px;
            color: #000;
        }

        .brownBtn {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            border: none;
            margin-top: 20px;
            color: white;
            font-family: FuturaPTBook !important;
        }
    }
}

.remove-popup {
    svg {
        margin-right: 12px;
        margin-top: 12px;
    }

    .form-control {
        display: block;
        width: 85%;
        margin: auto;

        @media screen and(max-width:575px) {
            width: 100%;
        }
    }

    .block-user-popup {
        padding: 40px 30px;
        margin: 0px auto;
        width: 80%;
        padding-bottom: 40px;
        text-align: center;

        @media screen and(max-width:575px) {
            padding: 20px 0px;
            padding-bottom: 20px;
        }

        h5 {
            font-size: 18px;
            margin-bottom: 15px;
            color: #000;
        }

        .grayBtn {
            padding: 6px 30px;
            background: #999999;
            text-transform: initial;
            box-shadow: none;
            color: #ffffff;
            border: none;
            margin: auto 20px;
            margin-top: 25px;
            color: white;
            font-family: FuturaPTBook !important;

            @media screen and(max-width:575px) {
                margin-top: 10px;
            }
        }

        .brownBtn {
            padding: 6px 30px;
            background: #c3724e;
            text-transform: initial;
            box-shadow: none;
            border: none;
            margin: auto 20px;
            margin-top: 25px;
            color: white;
            font-family: FuturaPTBook !important;

            @media screen and(max-width:575px) {
                margin-top: 10px;
            }
        }
    }
}

.cartsettings {
    .heading {
        margin-top: 10px;
    }

    .heading h2 {
        font-size: 21px;
    }
}

.cartrulesettings {
    .heading {
        margin-top: 10px;
    }

    .heading h2 {
        font-size: 21px;
    }
}

.messages {
    .response {
        position: relative;
        margin-top: 15px;

        .send-btn {
            text-align: left;

            @media screen and (max-width: 575px) {
                text-align: left;
            }

            .sendbtn {
                background-color: transparent !important;
                box-shadow: none !important;
                font-family: Mulish-SemiBold !important;
                border-radius: 5px !important;
                right: 10px;
                color: #b16846;

                @media screen and (max-width: 1024px) {
                    right: 5px;
                }

                @media screen and (max-width: 769px) {
                    right: 2px;
                }

                @media screen and (max-width: 426px) {
                    right: 6px;
                }

                @media screen and (max-width: 320px) {
                    right: 0px;
                }
            }
        }

        .text {
            position: absolute;
            right: -15px;
            top: 0px;
            color: #c3724e;
        }

        input {
            width: 85% !important;
            padding: 10px !important;
            border-radius: 8px;
            border: 1px solid #818181;
            margin: 0px 5px;
            outline: none;
            background-color: #fbfbfb !important;

            @media screen and (max-width: 1024px) {
                width: 83% !important;
            }

            @media screen and (max-width: 992px) {
                width: 80% !important;
            }

            @media screen and (max-width: 768px) {
                width: 78% !important;
            }

            @media screen and (max-width: 575px) {
                width: 65% !important;
            }

            @media screen and (max-width: 426px) {
                width: 73% !important;
            }

            @media screen and (max-width: 376px) {
                width: 70% !important;
            }

            @media screen and (max-width: 320px) {
                width: 66% !important;
            }
        }

        .abcicon {
            font-size: 30px;
            cursor: pointer;
        }

        .imageicon {
            font-size: 20px;
            cursor: pointer;
        }
    }

    .heading {
        margin-bottom: 20px;

        h2 {
            margin-bottom: 0px;
            font-size: 21px;
            line-height: 1.7;
        }
    }

    .messagesidebar {
        background: #fff;
        border-radius: 14px;

        .heading {
            margin-bottom: 0px;
            padding: 10px;
            text-align: center;
            border-bottom: 1px solid #999999;

            h2 {
                margin-bottom: 0px;
                font-size: 18px;
                line-height: 1.7;
            }

            svg {
                cursor: pointer;
            }
        }

        .nav-link.active {
            background-color: transparent !important;

            .inbox {
                background-color: #b16846;

                h2,
                p {
                    margin: 0px;
                    font-size: 14px;
                    color: #f4f4f4;
                }
            }
        }

        .chat-list {
            height: 400px;
            overflow-y: scroll;

            .inbox {
                background-color: #fff;
                border-radius: 13px;
                padding: 5px;

                .profile {
                    position: relative;

                    .dot {
                        position: absolute;
                        background: #00c92c;
                        width: 8px;
                        top: 26px;
                        right: 9px;
                        border-radius: 100%;
                        height: 8px;
                    }
                }
            }

            .inbox:hover {
                background-color: #b16846;

                h2,
                p {
                    margin: 0px;
                    font-size: 14px;
                    color: #f4f4f4;
                }
            }

            .inbox {
                img {
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                    margin-left: 10px;
                }

                h2 {
                    margin: 0px;
                    font-size: 14px;
                    color: #000;
                }

                p {
                    margin: 0px;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
}

.message-dialog {
    h2 {
        font-size: 22px;
    }

    svg {
        cursor: pointer;
    }

    .brownBtn {
        background-color: #c3724e;
        border-color: #c3724e;
        margin: 5px;
        font-family: FuturaPTBook !important;
    }

    .user-list {
        .inbox {
            margin-top: 20px;

            img {
                border-radius: 50%;
                height: 40px;
                width: 40px;
                margin-right: 10px;
            }

            h2 {
                margin: 0px;
                font-size: 14px;
                color: #000;
            }
        }
    }
}

.messagecontent {
    background: #fff;
    border-radius: 14px;

    @media screen and(max-width:768px) {
        margin-top: 25px;
    }

    .chat-list {
        height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .inbox {
        background-color: #b16846;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        padding: 10px;

        img {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-right: 10px;
            margin-left: 10px;
        }

        h2 {
            margin: 0px;
            font-size: 18px;
            color: #fff;
        }

        p {
            margin: 0px;
            font-size: 14px;
            color: #999999;
        }
    }

    .content-detail {
        .heading {
            text-align: center;

            h2 {
                color: Grey;
                font-size: 12px;
            }
        }

        img {
            height: 30px;
            width: 30px;
            border-radius: 100%;
            margin-right: 10px;
        }

        p {
            margin: 0px;
            background: aqua;
            display: inline-block;
            padding-right: 10px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
    }

    .message-list {
        width: 220px;
        margin-top: 10px;
        margin-left: 15px;

        @media screen and(max-width:768px) {
            margin-top: 14px;
        }

        img {
            height: 38px;
            width: 38px;
            border-radius: 100%;
            margin-right: 10px;
        }

        p {
            margin: 0px;
            background: #c3724e;
            color: #fff;
            display: flex;
            align-items: center;
            padding-right: 10px;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        h5 {
            margin: 0px;
            text-align: end;
            color: #999999;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .message-list-img {
        .message-list {
            width: 220px;

            img {
                height: 35px;
                width: 35px;
                border-radius: 100%;
                margin-right: 10px;
            }

            p {
                margin: 0px;
                background: #c3724e;
                color: #fff;
                display: inline-block;
                padding-right: 10px;
                border-top-left-radius: 17px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            h5 {
                margin: 0px;
                text-align: end;
                color: #999999;
                font-size: 12px;
                margin-top: 5px;
            }
        }

        width: 220px;
        margin-top: 10px;

        img {
            height: 35px;
            width: 35px;
            border-radius: 100%;
        }

        .content {
            img {
                height: 100px;
                width: 100%;
                border-radius: 0px;
                object-fit: cover;
                margin: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 12px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 12px;
            }
        }

        p {
            margin: 0px;
            background: #c3724e;
            color: #fff;
            padding: 7px;
            width: 100%;
            display: inline-block;
            padding-right: 10px;
            border-top-left-radius: 17px;
            border-bottom-left-radius: 17px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        h5 {
            margin: 0px;
            text-align: end;
            color: #999999;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .message-right-list {
        margin-left: auto;
        text-align: end;
        align-items: center;
        margin: 10px 15px;

        p {
            padding: 10px;
            background-color: #fbfbfb;
            border-radius: 10px;
            margin: 0px;
            display: inline-block;
        }

        h5 {
            text-align: end;
            color: #999999;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .checkicon {
        font-size: 14px;
    }
}

.membership-tag {
    position: relative;
    background-image: url(../images/home/mymembership.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 250px;
    height: 150px;
    background-position: inherit;

    .star {
        position: absolute;
        height: 40px;
        top: 5%;
        right: 10%;
        transform: translate(10px, 10px);
    }

    h5 {
        position: absolute;
        font-size: 30px;
        color: #fff;
        left: 6%;
        margin: 0px;
        transform: translate(0px, 0px);
        font-family: 'FuturaPTMedium' !important;
        bottom: 20%;
    }

    p {
        position: absolute;
        font-size: 12px;
        color: #fff;
        left: 7%;
        font-family: 'FuturaPTMedium' !important;
        transform: translate(0px, 0px);
        bottom: 0%;
    }
}

.membership {
    img {
        width: 100%;
    }

    h5 {
        color: #8c5d2f;
        opacity: 0.8;
        font-size: 14px;
        margin: 10px;
        font-family: 'FuturaPTBook' !important;
    }

    h3 {
        color: #8c5d2f;
        opacity: 0.8;
        font-size: 14px;
        margin-left: 10px;
        font-family: 'FuturaPTMedium' !important;

        span {
            font-size: 25px;
            text-transform: uppercase;
            font-family: 'quigley' !important;
            font-weight: bold;
            padding: 0px 5px;
        }
    }

    .details {
        border-bottom: 1px solid #e5d6c7;
        padding: 15px 0px;
        margin-left: 10px;

        .dot {
            background-color: #a07b56;
            width: 40px;
            height: 40px;
            border-radius: 100%;

            @media screen and (max-width: 576px) {
                width: 45px;
                height: 35px;
            }

            @media screen and (max-width: 376px) {
                width: 50px;
                height: 35px;
            }

            @media screen and (max-width: 320px) {
                width: 60px;
                height: 30px;
            }
        }

        h5,
        p {
            font-size: 14px;
            color: #8c5d2f;
            font-family: 'FuturaPTBook' !important;
        }

        .content-details {
            padding-left: 20px;
        }
    }
}

.visualization {
    background-color: #fff8f2;
    padding: 5% 0%;

    @media screen and (max-width: 575px) {
        padding: 10% 0%;
    }

    h5 {
        font-size: 14px;
        color: #8c5d2f;
        text-align: center;
    }

    .tabs {
        background-color: #fff8f2;
        height: 375px;
        border-radius: 12px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .tabs-content {
        padding-bottom: 10px;

        img {
            height: 120px;
            display: flex;
            margin: auto;
            border-radius: 12px;
            width: 120px;
            object-fit: cover;

            @media screen and (max-width: 769px) {
                height: 120px;
                border-radius: 12px;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .visual-input {
        width: 100%;
    }

    // .canvas-image{
    //     width: 100% !important;

    //     canvas{
    //         width: 100% !important;
    //     }
    // }

    // canvas-content .konvajs-content canvas{
    //     width: 100% !important;
    //     height: 400px !important;
    // }

    .visual-dropdown {
        position: absolute;
        top: 3px;
        z-index: 999;
        left: 3px;
        .dropdown-toggle {
            color: white;
            font-family: 'FuturaPTBook' !important;
            font-size: 18px;
            font-weight: 100 !important;
        }

        .dropdown-menu {
            background-color: #565e64;

            .dropdown-item {
                color: white;
                font-family: 'FuturaPTBook' !important;
                font-size: 18px;
                font-weight: 100 !important;

                &:hover {
                    background-color: #838689a6;
                }
            }
        }
    }

    .drop-line {
        margin-top: 15px;
        text-align: center;

        p {
            font-size: 17px;
            color: #8c5d2f;
            font-family: 'FuturaPTBook' !important;
            margin-bottom: 0px;
        }
    }

    .tab-content {
        background-color: #fff;
        border-radius: 12px;
        position: relative;
        height: 400px;

        .visual-upload-img {
            position: absolute;
            top: 30%;
            left: 40%;
            transform: translate(-40px, 20px);
            text-align: center;

            img {
                width: 100px;
            }

            P {
                font-size: 17px;
                margin-top: 15px;
                color: #8c5d2f;
                font-family: 'FuturaPTBook' !important;
                margin-bottom: 0px;
            }
        }
    }
}

.other-detail {
    svg {
        margin-right: 5px;
        position: relative;
        top: -3px;
    }
}

.fw-bold {
    font-weight: 600;
}

.other-product-detail {
    margin-top: 25px;

    span {
        display: inline-flex;
        margin-bottom: 15px;
    }

    > div {
        span:first-child {
            width: 25%;
        }

        span:last-child {
            width: 75%;
            font-family: 'FuturaPtBook' !important;
            font-size: 18px;
            opacity: 0.8;
        }
    }

    .faq-style {
        span:first-child {
            width: 100%;
            margin-bottom: 5px;
        }

        span:last-child {
            width: 100%;
            font-family: 'FuturaPtBook' !important;
            font-size: 18px;
            opacity: 0.8;
        }
    }
}

.video-player {
    margin-top: 20px;
    padding-left: 110px;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
        width: 100% !important;
        height: 400px !important;
    }

    .video-react-big-play-button,
    .video-react-big-play-button:focus,
    .video-react:hover .video-react-big-play-button,
    .video-react .video-react-big-play-button:focus {
        z-index: 100;
        background-color: #8c5d2f;
        border: 0px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        position: initial !important;
    }

    .video-react-poster {
        background-size: cover !important;
    }

    .video-react-big-play-button:before {
        position: relative !important;
        font-size: 50px;
        top: 5px;
    }
}

.upload-video-success {
    border: 1px solid #888888;
    height: 150px;
    width: 150px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
    padding: 15px;

    > div {
        margin-top: 30px;
    }

    svg {
        color: #4caf50;
        font-size: 32px;
    }
}

.about-us {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1000px) {
        height: auto;
        padding: 20px 0px;
    }

    .floatingBox1 {
        text-align: center;
        background: #ffffffb8;
        width: 85%;
        margin: 0 auto;
        padding: 30px 100px;

        @media screen and (max-width: 1000px) {
            padding: 20px;
            width: 100%;
        }

        h1 {
            color: #8c5d2f;
            margin-bottom: 5px;
            font-family: 'FuturaPTBook' !important;
            font-size: 45px;
            font-weight: 100;

            @media screen and (max-width: 1000px) {
                font-size: 26px;
            }
        }

        p {
            color: #8c5d2f;
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 30px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 1000px) {
                font-size: 16px;
            }
        }
    }
}

.our-approach {
    padding: 70px 0px;
    background-color: #fff8f2;

    @media screen and (max-width: 1000px) {
        padding: 50px 0px;
    }

    .floatingBox2 {
        background: #fff8f2;
        width: 85%;
        margin: 0 auto;

        @media screen and (max-width: 1000px) {
            width: 100%;
        }

        img {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }

        h1 {
            color: #8c5d2f;
            margin-bottom: 20px;
            font-family: 'FuturaPtBook' !important;
            font-size: 23px;
            font-weight: 600;

            @media screen and (max-width: 1000px) {
                font-size: 19px;
            }

            @media screen and (max-width: 767px) {
                margin-top: 30px;
            }
        }

        p {
            color: #8c5d2f;
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 30px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 1000px) {
                font-size: 16px;
            }
        }
    }
}

.about-us-last {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1000px) {
        height: auto;
        padding: 20px 0px;
    }

    .floatingBox3 {
        text-align: center;
        background: #ffffffb8;
        width: 85%;
        margin: 0 auto;
        padding: 30px 100px;

        @media screen and (max-width: 1000px) {
            padding: 20px;
            width: 100%;
        }

        h1 {
            color: #8c5d2f;
            margin-bottom: 20px;
            font-family: 'FuturaPtBook' !important;
            font-size: 23px;
            font-weight: 600;

            @media screen and (max-width: 1000px) {
                font-size: 19px;
            }
        }

        p {
            color: #8c5d2f;
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 30px;
            font-family: 'FuturaPtBook' !important;

            @media screen and (max-width: 1000px) {
                font-size: 16px;
            }
        }
    }
}

.header-popover .MuiPopover-paper {
    margin-top: 10px;
}

.popover-box {
    padding: 10px;

    input,
    input:focus-visible {
        border: 0px;
        outline-width: 0;
    }

    button,
    button:hover {
        background-color: #8c5d2f;
        font-weight: 200;
        font-family: 'FuturaPtBook' !important;
        margin-left: 15px;
        height: 30px;
    }
}

.rdt_Pagination {
    display: flex !important;
    justify-content: flex-start !important;
}

.shareMain div div:last-child {
    display: none;
}

.shareMain .MuiInput-root {
    display: block !important;
}

.shareMain button {
    padding: 0px;
}

.input-popup {
    margin-top: 10px;

    input {
        height: 35px;
        border: none;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 450px;

        @media screen and (max-width: 768px) {
            width: auto;
        }
    }

    button {
        width: 150px;
        box-shadow: none;
        margin-left: 20px;
        border-radius: 0px !important;
        text-transform: capitalize;
        background-color: #8c5d2f !important;
        border: 2px solid #8c5d2f;
        box-shadow: none !important;
        color: white !important;
        font-family: 'FuturaPtMedium' !important;
    }

    .MuiInput-root:before {
        border-bottom: none;
    }

    .MuiInput-root:after {
        border-bottom: none;
    }

    .MuiInput-root:hover:not(.Mui-disabled):before {
        border-bottom: none;
    }
}

.editorNew {
    border: solid 1px #000;
    width: 300px;
    min-height: 32px;
    padding: 0 10px;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    white-space: pre;
    -ms-scroll-snap-type: mandatory;
    -ms-scroll-snap-points-x: snapInterval(0%, 100%);
}

.editorNew:focus-visible {
    outline: none !important;
}

.editorNew_scroll {
    overflow-x: scroll;
}

@media screen and (max-width: 475px) {
    .toggle-btn .MuiToggleButtonGroup-root {
        display: block;
    }

    .filter-menu .toggle-btn .MuiToggleButtonGroup-root .MuiToggleButton-root {
        padding: 15px 25px !important;
    }
}

.dot-icon {
    position: absolute;
    height: 15px;
    left: 5px;
    top: 4px;
    color: red;
}

.fixed-designer-img {
    width: 250px;
    display: inline-block;
}

.fixed-designer-content {
    display: inline-flex;
    width: 100%;
    align-items: center;

    .wrapper-content {
        width: 100%;
        display: inline-flex;
        align-items: center;
    }

    .description-box {
        padding-left: 25px;
        min-width: 400px;
    }

    .following-btn {
        width: 100%;
    }

    @media screen and (max-width: 1000px) {
        .description-box {
            min-width: 275px;
        }
    }

    @media screen and (max-width: 770px) {
        display: block;
        text-align: center;

        .wrapper-content {
            margin-top: 20px;
            text-align: left;
        }
    }

    @media screen and (max-width: 575px) {
        .description-box {
            padding-left: 0px;
        }

        .following-btn {
            text-align: end !important;
            margin-top: 0px !important;
        }
    }

    @media screen and (max-width: 450px) {
        .wrapper-content {
            display: block;
            margin-top: 0px;
        }

        .description-box {
            text-align: center !important;
        }

        .following-btn {
            text-align: center !important;
            margin-top: 10px !important;
        }
    }
}

.shareMain > div > div {
    padding: 0px;
}
.diplayTimeline {
    .MuiTimelineItem-root::before {
        display: none;
    }
}
.trackingPopup {
    max-width: 650px;
    .accordionSummary {
        .MuiAccordionSummary-content {
            margin: 0;
        }
        .MuiAccordionDetails-root {
            padding: 0px;
            min-height: 35px;
        }
        .MuiTimeline-root {
            padding: 0;
        }
    }
}
.productTopM {
    margin-bottom: 44px !important;
}
.productBottomM {
    position: absolute;
    top: 80px;
}
.checkWidth {
    cursor: pointer;
    width: 200px;
    overflow: hidden;
}

.tooltip-ship {
    font-size: 14px;
    width: 250px;
    background-color: white;
    color: #616161;
    padding: 10px;
    line-height: 24px;
    font-weight: normal;

    span {
        display: inline-block;
        font-family: inherit !important;
    }

    span:last-child {
        float: right;
    }
}
.productBottomM {
    margin-top: 65px;
}
.allProducts {
    background: transparent;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    .tag {
        border: 1px solid #c3724e;
        display: inline-block;
        padding: 2px 3px;
        border-radius: 4px;
        p {
            margin-bottom: 0px !important;
            font-size: 12px;
            margin-right: 5px;
            line-height: 22px;
        }
        svg {
            width: 13px;
        }
    }
}
.paymentImg {
    width: 130px;
    height: 130px;
    display: block;
    border-radius: 100px;
}
.paymentSelect select {
    width: 100%;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    outline: none;
    height: 38px;
    font-family: Mulish-SemiBold !important;
}
.paymentDatePicker .react-datepicker-wrapper input {
    width: 100%;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    outline: none;
    height: 38px;
}
.approval-btn {
    margin: 20px;

    .reject {
        background-color: #e35757;
        color: white;
        box-shadow: none;
        border: none;
        border-radius: 5px;
        margin-right: 20px;
        text-transform: initial;
        padding: 10px 30px;
        font-family: Mulish-SemiBold !important;
    }

    .approve {
        background-color: #57e376;
        color: white;
        border: none;
        border-radius: 5px;
        box-shadow: none;
        text-transform: initial;
        padding: 10px 25px;
        font-family: Mulish-SemiBold !important;
    }
}

.form-control:disabled {
    background-color: #e9ecef !important;
}

.rdw-editor-main {
    h1, h2, h3, h4, h5, h6, strong, span, button {
        font-family: Roboto !important;
        color: #616161 !important;
    }
}

.stories-details p span {
    color: #8c5d2f!important;
    font-family: Roboto !important;
}

.stories-details p ul li {
    color: #8c5d2f;
    font-size: 18px;
    font-family: "FuturaPtBook" !important;
    opacity: 0.8;
}
.btn-cancel {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    .btn-primary {
        padding: 6px 35px;
        background: #c3724e;
        text-transform: initial;
        box-shadow: none;
        border: none;
        font-size: 14px;
        font-weight: bold;
        font-family: Mulish-Bold !important;
    }
}
.MuiSwitch-switchBase.Mui-checked {
    color: #c4724e;
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: #c4724e;
}
.tooltipStyle {
    h5 {
        margin-bottom: 4px;
        font-size: 13px;
        font-weight: bold;
        color: white;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.disabled-role {
    svg {
        display: none;
    }

    .MuiSelect-select {
        padding: 4px 15px !important;
    }
}

.version-box {
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}

.apexcharts-toolbar {
    .apexcharts-zoom-icon,
    .apexcharts-pan-icon,
    .apexcharts-reset-icon,
    .apexcharts-menu-icon {
        display: none !important;
    }
}

.export-btn2 {
    background-color: #c3724e !important;
    border-color: #c3724e !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    border-radius: 7px !important;
    color: white;
    width: "100px";
  }

.no-border {
    border: 0px !important;
}

.approval-detail-popup .table tbody tr td.no-paddingTop {
    padding-top: 0px !important;
}

.link-notif {
  color: #4141d8;
  cursor: pointer;
  text-decoration: underline;
}

.product-list label {
    margin-left: 1px !important;
}

.icon-notif svg {
    color: #a1a1a1;
}

.notif-wrapper {
    .scrollbar-container ul {
        max-width: 400px;
        width: 400px;
    }
    
    select {
        border: 1px solid #ddd;
    }

    .MuiFormControl-root fieldset {
        display: none;
    }

    .list-container {
        padding-left: 0px;

        h6 {
            margin-bottom: 2px;
        }
    }

    ul .MuiListItemSecondaryAction-root  {
        top: 16px;
        right: 40px;
    }

    .desc-text {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 7px;
    }
    .MuiList-root.MuiList-padding div {
        cursor: default;
    }

    .comment-color {
        color: #df4949 !important;
    }

    .next-color {
        font-size: 13px;
        background-color: #c3724e;
        padding: 5px;
        border-radius: 5px;
        color: white;
    }
    .scrollbar-container {
        .MuiToolbar-root .MuiButtonBase-root {
            color: #8c5d2f;
            border-bottom: 1px solid #8c5d2f !important;
        }
    }
}

.hs-code-input {
    border: 1px solid #c4c4c4;
    padding: 8px;
    border-radius: 8px;
    outline: none;
}

.MuiLoadingButton-root.Mui-disabled {
    background-color: #c3724e;
    opacity: 0.8;
}