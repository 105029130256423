@font-face {
    font-family: Redressed-Regular;
    src: url(../fonts/Redressed-Regular.ttf);
}
@font-face {
    font-family: Mulish-SemiBold;
    src: url(../fonts/Mulish-SemiBold.ttf);
}
.register {
    background-image: url(../images/register.png);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .register-back {
        background: #fffffff0;
        padding: 30px;
        font-family: Mulish-SemiBold !important;
        margin: 50px 0px;
        img {
            width: 160px;
        }
        h3 {
            font-size: 40px;
            font-family: Redressed-Regular !important;
        }
        .edit-icon {
            svg {
                color: #c3724e;
                font-size: 30px;
            }
        }
        .register-form {
            text-align: center;
            .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                border-radius: 0px;
                border: 1px solid #ffffff3b;
                border-bottom: 1px solid #a3a3a3;
            }
            .MuiFormControl-root {
                width: 100%;
                font-family: Mulish-SemiBold !important;
                font-size: 15px;
                .css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
                }
                .css-1480iag-MuiInputBase-root-MuiInput-root:after {
                    border-bottom: 2px solid #c3724e !important;
                }
            }
            .MuiInputLabel-outlined{
                padding-top: 4px;
                font-family: Mulish-SemiBold !important;
                font-size: 14px;
                background: transparent;
            }
           
            .MuiInputBase-root {
                width: 100%;
                padding-top: 10px;
                font-family: Mulish-SemiBold !important;
                font-size: 15px;
                background: transparent;
                .MuiSelect-select {
                    background: transparent;
                    border-bottom: 1px solid gray;
                    border-radius: 0px;
                    padding-bottom: 5px;
                    color: black;
                    font-size: 15px;
                    text-align: left;
                    padding: 4px 0 5px;
                    padding-left: 0px;
                    padding-right: 0px;
                    em {
                        font-style: normal;
                        font-weight: 100;
                    }
                }
                fieldset {
                    border: 0;
                }
                .MuiButtonBase-root {
                    padding: 0px;
                }
            }
            .MuiInput-root:hover:not(.Mui-disabled):before {
                border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
            }
            .MuiInput-root:after {
                border-bottom: 2px solid #c3724e !important;
            }
            .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
                padding: 5px 10px !important;
            }
            .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                height: 40px !important;
                right: 0px;
            }
            .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
                color: #a3a3a3;
                text-align: left;
                font-weight: 100 !important;
                padding: 5px 2px !important;
            }
            .css-ykhttz-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
                border-bottom: 1px solid #92908f !important;
            }
            .css-ykhttz-MuiInputBase-root-MuiInput-root:after {
                border-bottom: 1px solid #92908f !important;
            }
            .css-1tndf5z-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
                border-bottom: 1px solid #92908f;
            }
            .css-1tndf5z-MuiInputBase-root-MuiInput-root:after {
                border-bottom: 1px solid #92908f !important;
            }
            .date-birth {
                h5 {
                    color: #c3724e;
                    text-align: left;
                    font-family: Mulish-SemiBold;
                    margin-bottom: 0px;
                    margin-top: 35px;
                    font-size: 17px;
                }
                .MuiInputBase-root {
                    padding-top: 10px;
                    background: transparent;
                }
                .MuiSelect-select {
                    background: transparent;
                    border-bottom: 1px solid gray;
                    border-radius: 0px;
                    padding-bottom: 5px;
                    color: black;
                    font-size: 15px;
                    text-align: left;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                fieldset {
                    border: 0;
                }
            }
            .country-select{
                width: 100%;
                top: 14px;
                input{
                    width: 100%;
                    padding-left: 0px;
                    border: none;
                    background: transparent;
                    border-bottom: 1px solid #717170;
                    outline: none;
                    font-family: Mulish-SemiBold !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 28px;
                    color: black !important;
                    opacity: 1;
                }
                .flag-dropdown {
                    right: 0;
                    left: auto;
                    .country-list{
                        max-width: 276px;
                    }
                }
            }
            .register-btn button {
                background: #c3724e;
                color: white;
                text-transform: initial;
                padding: 7px 60px;
                border-radius: 10px;
                box-shadow: none;
                font-size: 17px;
                margin-top: 60px;
                font-family: Mulish-SemiBold !important;
            }
            .register-login p {
                margin-top: 10px;
                color: #a3a3a3;
                a {
                    text-decoration: none;
                    color: #c3724e;
                }
            }
            #contained-button-file {
                display: none;
                border: none;
            }
            .css-a88p61-MuiInputBase-root-MuiInput-root:before {
                // border-bottom: none;
            }
            .upload-buttons {
                margin-top: 35px;
                text-align: left;
                label {
                    color: #a3a3a3;
                    font-size: 18px;
                    width: 100%;
                    height: 100%;
                }
                .MuiInput-root {
                    display: block;
                    border-radius: 5px;
                    width: 150px;
                    height: 150px;
                    border: 1px solid #a3a3a3;
                    margin-top: 5px;
                }
                .upload-heading {
                    p {
                        margin-bottom: 5px;
                        color: #d3d2d0;
                    }
                }
                .upload-btn {
                    position: relative;
                    border-radius: 10px;
                    border: 2px solid gray;
                    width: 250px;
                    height: 210px;
                    cursor: pointer;
                    .plus-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-17px, -17px);
                        cursor: pointer;
                        svg {
                            font-size: 30px;
                        }
                    }
                    .fixed-plus-icon {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: absolute;
                        svg {
                            font-size: 30px;
                        }
                    }
                    #file-input {
                        display: none;
                    }
                }
            }
        }
    }
}
